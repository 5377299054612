import { Globals } from '../data/Globals';
import { gsap } from 'gsap';
import { FingerPoseEstimator } from '../utils/FingerPoseEstimator';

export class HandPoseDetection {
	private _predictionCount: number = 0;
	private _firstPrediction: boolean = false;
	private _successCallback: Function;
	private _userFeedVideo: HTMLVideoElement;

	private _handDetectionDisabled: boolean = false;

	// Used for the user fed video
	private _videoCanvasContainer: HTMLCanvasElement = document.createElement('canvas');
	private _videoCanvasCtx: CanvasRenderingContext2D = this._videoCanvasContainer.getContext('2d');

	// The Hand graphics
	private _canvasOverlayElement: HTMLCanvasElement = document.createElement('canvas');
	private _canvasOverlayCtx: CanvasRenderingContext2D = this._canvasOverlayElement.getContext('2d');

	private _previousTime: Number = -1;

	public fingerPoseEstimator: FingerPoseEstimator;

	public _fingerPoseResults;

	private _getVideoRatio;

	private _loaded: boolean = false;

	private allowDetection: boolean = false;

	constructor(userFeedVideo) {
		this.fingerPoseEstimator = new FingerPoseEstimator(null);
		this._userFeedVideo = userFeedVideo;

		gsap.set(this._videoCanvasContainer, { scaleX: -1 });

		//	this._videoCanvasContainer.style.zIndex = 1000;

		//gsap.set(this._canvasOverlayElement, {scaleX: -1});

		window.addEventListener('resize', this.onResize);
		this.onResize();
	}

	private onResize = event => {
		var getCropWidth = window.innerWidth / 2; // 500
		var getCropHeight = window.innerHeight; // 350

		if (Globals.IS_MOBILE === true) {
			getCropWidth = window.innerWidth; // 500
			getCropHeight = window.innerHeight / 2; // 350

			this._getVideoRatio = Globals.VIDEO_HEIGHT / Globals.VIDEO_WIDTH;

			this._userFeedVideo.width = window.innerWidth;
			this._userFeedVideo.height = window.innerWidth * this._getVideoRatio;
		}

		console.log('Globals.IS_MOBILE  : ' + Globals.IS_MOBILE);
		console.log('window.innerWidth : ' + window.innerWidth);
		/*if (Globals.IS_MOBILE !== true) {
			gsap.set(this._videoCanvasContainer, {scaleX: -1});
		} else {
			gsap.set(this._videoCanvasContainer, {scaleX: 1});
		}*/

		// Resize canvas
		this._videoCanvasContainer.width = getCropWidth;
		this._videoCanvasContainer.height = getCropHeight;

		this._canvasOverlayElement.width = getCropWidth;
		this._canvasOverlayElement.height = getCropHeight;

		this.drawVideoToCanvas();
	};

	private drawVideoToCanvas = () => {
		if (Globals.IS_MOBILE === true) {
			var getCropWidth = window.innerWidth; // 500
			var getCropHeight = window.innerHeight / 2; // 350
			var cropRatio = getCropHeight / getCropWidth;

			var videoWidth = this._userFeedVideo.videoWidth; // 1280 - 640
			var videoHeight = this._userFeedVideo.videoHeight; // 720

			var cropWidth = videoWidth;
			var cropHeight = videoHeight * cropRatio;

			var sy = (videoHeight - cropHeight) / 2;
			var sx = (videoWidth - cropWidth) / 2;

			if (cropHeight > videoHeight) {
				console.log('**** higher');
				cropRatio = getCropWidth / getCropHeight;
				cropWidth = videoHeight * cropRatio;
				cropHeight = videoHeight;
				sy = 0;
			}

			/*	if (cropHeight > videoHeight) {
					console.log('**** higher');
				cropRatio = getCropWidth / getCropHeight;
				cropWidth = videoHeight * cropRatio;
				cropHeight = videoHeight;
				sx = 0;
			}*/
			/*	console.log('getCropWidth : ' + getCropWidth);
			console.log('getCropHeight : ' + getCropHeight);

			console.log('videoWidth : ' + videoWidth);
			console.log('videoHeight : ' + videoHeight);

			console.log('sx : ' + sx);
			console.log('sy : ' + sy);

			console.log('cropWidth : ' + cropWidth);
			console.log('cropHeight : ' + cropHeight);*/
			this._videoCanvasCtx.drawImage(this._userFeedVideo, 0, videoHeight / 2, videoWidth, videoHeight / 2, 0, 0, window.innerWidth, window.innerHeight / 2);
			//this._videoCanvasCtx.drawImage(this._userFeedVideo, 0, 0, cropWidth, cropHeight, 0, 0, getCropWidth, window.innerHeight / 2);
		} else {
			var getCropWidth = window.innerWidth / 2; // 500
			var getCropHeight = window.innerHeight; // 350
			var cropRatio = getCropHeight / getCropWidth;

			var videoWidth = this._userFeedVideo.width / 2; // 1280 - 640
			var videoHeight = this._userFeedVideo.height; // 720

			var cropWidth = videoWidth;
			var cropHeight = videoWidth * cropRatio;

			var sy = (videoHeight - cropHeight) / 2;

			if (cropHeight > videoHeight) {
				console.log('**** higher');
				cropRatio = getCropWidth / getCropHeight;
				cropWidth = videoHeight * cropRatio;
				cropHeight = videoHeight;
				sy = 0;
			}
			var sx = 0;
			if (Globals.LEFT_HAND_SELECTED === true) {
				sx = cropWidth;
			}

			this._videoCanvasCtx.drawImage(this._userFeedVideo, sx, sy, cropWidth, cropHeight, 0, 0, window.innerWidth / 2, getCropHeight);
			//this._videoCanvasCtx.drawImage(this._userFeedVideo, 0, sy, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
		}
	};

	private findBoundingBox = arrayValues => {
		var lowestX = Number.MAX_VALUE;
		var lowestY = Number.MAX_VALUE;
		var highestX = -Number.MAX_VALUE;
		var highestY = -Number.MAX_VALUE;
		var length = arrayValues.length;
		for (var i = 0; i < length; i++) {
			var x = arrayValues[i].x;
			var y = arrayValues[i].y;
			if (x < lowestX) {
				lowestX = x;
			}
			if (x > highestX) {
				highestX = x;
			}

			if (y < lowestY) {
				lowestY = y;
			}
			if (y > highestY) {
				highestY = y;
			}
		}

		return { x1: lowestX, y1: lowestY, x2: highestX, y2: highestY };
	};

	private onResults = result => {
		this._predictionCount++;

		if (this._predictionCount === 1) {
			this._firstPrediction = false;
			this._loaded = true;
			this._handDetectionDisabled = true;
			Globals.PAUSE_HAND_DETECTION = true;
			this._successCallback();
		}
		var allowPredict = false;
		if (this.allowDetection) {
			allowPredict = true;
		}

		if (Globals.PAUSE_HAND_DETECTION === true) {
			allowPredict = false;
		}
		/*	if (result.multiHandLandmarks.length > 1) {
			console.log(result)
		}*/

		if (allowPredict === true) {
			this._canvasOverlayCtx.save();
			this._canvasOverlayCtx.clearRect(0, 0, this._canvasOverlayElement.width, this._canvasOverlayElement.height);
			if (result.multiHandLandmarks && result.multiHandedness) {
			//	console.log(result)
				var landmarks = result.multiHandLandmarks[0];
				var newLandMarks = [];
				if (Globals.ACTIVE_VIEW) {
					//	var length = landmarks.length;
					//	console.log(landmarks)
					for (var i = 0; i < 21; i++) {
						var currentLandmark = {x: 0, y: 0, z: 0};
						if (landmarks && landmarks[i]) {
							currentLandmark = landmarks[i];
						}

					//	console.log(currentLandmark)
						newLandMarks.push([currentLandmark.x, currentLandmark.y, currentLandmark.z]);
						// For Left hand we are reverting all the positions
						if (Globals.LEFT_HAND_SELECTED) {
							newLandMarks[i][0] = newLandMarks[i][0] * -1;
						}
					}

					this._fingerPoseResults = this.fingerPoseEstimator.estimate(newLandMarks);

					// NOTE: We are only accepting hands of a certain size - to have less false positives
					var handSize = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(newLandMarks[0], newLandMarks[5]) * 10;
					if (handSize > 0.7) {
						this.drawHands(this._canvasOverlayCtx, landmarks, window.HAND_CONNECTIONS, { color: '#ffffff' });
						Globals.ACTIVE_VIEW.reactToDOMCursor(this._fingerPoseResults, newLandMarks);
					}
				}
			}
			this._canvasOverlayCtx.restore();
		}
	};

	private f = a => {
		var c = 0;
		return function() {
			return c < a.length ? { done: !1, value: a[c++] } : { done: !0 };
		};
	};

	private h = a => {
		var c = 'undefined' != typeof Symbol && Symbol.iterator && a[Symbol.iterator];
		return c ? c.call(a) : { next: f(a) };
	};

	private x = (a, c) => {
		return c && a instanceof Function ? a(c) : a;
	};

	private drawHands = (a, c, b, d) => {
		var i;
		if (c && b) {
			a.save();
			var e = a.canvas;

			b = this.h(b);
			var count = 0;
			for (var f = b.next(); !f.done; f = b.next()) {
				a.beginPath();
				var l = f.value;
				f = c[l[0]];
				l = c[l[1]];
				if (f && l) {
					i = count;

					a.strokeStyle = 'rgba(255,255,255,0.2)';
					if (Globals.FINGER_SPELLING_DATA && Globals.FINGER_SPELLING_DATA.thumb) {
						if (i >= 0 && i <= 3) {
							if (Globals.FINGER_SPELLING_DATA.thumb.percentageCorrect === 1) {
								a.strokeStyle = '#683AFF';
							}
						} else if (i >= 4 && i <= 8) {
							if (Globals.FINGER_SPELLING_DATA.index.percentageCorrect === 1) {
								a.strokeStyle = '#683AFF';
							}
						} else if (i >= 9 && i <= 12) {
							if (Globals.FINGER_SPELLING_DATA.middle.percentageCorrect === 1) {
								a.strokeStyle = '#683AFF';
							}
						} else if (i >= 13 && i <= 16) {
							if (Globals.FINGER_SPELLING_DATA.ring.percentageCorrect === 1) {
								a.strokeStyle = '#683AFF';
							}
						} else if (i >= 17 && i <= 20) {
							if (Globals.FINGER_SPELLING_DATA.little.percentageCorrect === 1) {
								a.strokeStyle = '#683AFF';
							}
						}
					}
					a.lineCap = 'round';
					a.lineWidth = 3;
					a.moveTo(f.x * e.width, f.y * e.height);
					a.lineTo(l.x * e.width, l.y * e.height);
					count++;
				}
				a.stroke();
				a.restore();
			}
		}
	};

	public loadHandPose = successCallback => {
		this._successCallback = successCallback;
		console.log('loadHandPose()');
		// 	return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.1/${file}`;

		// 0.1.1613506466

		// WORKING: 0.1.1612238212
		const hands = new Hands({
			locateFile: file => {
				return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.4.1675469240/${file}`;
			}
		});
		hands.setOptions({ selfieMode: true, maxNumHands: 1, minDetectionConfidence: 0.5, minTrackingConfidence: 0.5, modelComplexity: 1 });
		hands.onResults(this.onResults);

		console.log(hands);

		var userVideo = this._userFeedVideo;
		var previousTime = this._previousTime;
		var then = this;

		async function check() {
			await predict();
		}

		check();

		async function predict() {
			Globals.MAIN.statsHandTracking.begin();
			// Making sure that the video has actaully played to a new frame
			if (Globals.PAUSE_HAND_DETECTION === false && then._handDetectionDisabled !== true) {
				if (userVideo.currentTime !== previousTime) {
					then.drawVideoToCanvas();
					await hands.send({ image: then._videoCanvasContainer });
				}
				previousTime = userVideo.currentTime;
			}
			Globals.MAIN.statsHandTracking.end();
			requestAnimationFrame(predict);

			return;
		}
	};

	public updateVideoWithoutDetectionTurnedOn = () => {
		this.drawVideoToCanvas();

		requestAnimationFrame(this.updateVideoWithoutDetectionTurnedOn);
	};

	public allowHandAnalysis = () => {
		this.allowDetection = true;
		this.enabledHandDetection();
	};

	public disableHandDetection = () => {
		this._handDetectionDisabled = true;
	};

	public enabledHandDetection = () => {
		this._handDetectionDisabled = false;
	};

	public getVideoCanvas = () => {
		return this._videoCanvasContainer;
	};

	public getCanvasOverlay = () => {
		return this._canvasOverlayElement;
	};
}
