import { ViewManager } from '../managers/ViewManager';
import { DOMCursor } from '../utils/DOMCursor';
import { HandAnalyzer } from '../utils/HandAnalyzer';
import { HandStateManager } from '../utils/HandStateManager';
import { Preloader } from '../preloader/Preloader';
import { SetupVideo } from '../preloader/SetupVideo';
import { ActiveStateController } from '../utils/ActiveStateController';
import { Alphabet } from '../utils/Alphabet';
import { HandPoseDetection } from '../preloader/HandPoseDetection';
import { HandScene } from '../3d/HandScene';

export class Globals {
	public static DEBUG: boolean = false;
	public static DEBUG_TURN_OFF_HANDPOSE: boolean = false;
	public static DEBUG_SHOW_FPS_COUNTER: boolean = false;
	public static DEBUG_AUTO_SKIP_INTRO_SCREEN: boolean = true;
	public static SKIP_TO_MAIN_SCREEN: boolean = false;
	public static PAUSE_HAND_DETECTION: boolean = false;

	public static ALLOW_TO_TEST_WITH_KEYBOARD: boolean = true;

	public static VIDEO_WIDTH: number = 1280;
	public static VIDEO_HEIGHT: number = 720;
	public static VIDEO_WIDTH_CROP: number = Math.round(Globals.VIDEO_WIDTH / 1.6); // 400

	public static HAS_ALLOWED_WEBCAM: boolean = false;

	public static HAND_SCENE: HandScene;

	public static HAND_POSE_DETECTION: HandPoseDetection;

	public static FINGER_SPELLING_DATA: Object;
	public static ALPHABET: Alphabet;

	public static VIDEO_SETUP: boolean = false;

	public static DOMCursor: DOMCursor;
	public static MAIN;
	public static PRELOADER: Preloader;
	public static SETUP_VIDEO: SetupVideo;

	public static RIGHT_PALM_POINTING: boolean = false;

	public static HAND_ANALYZER: HandAnalyzer;
	public static USER_VIDEO_CONTAINER: HTMLDivElement;

	public static ACTIVE_VIEW;

	public static VIEW_MANAGER: ViewManager;

	public static IS_MOBILE: boolean = false;
	public static IS_SAFARI: boolean = false;

	public static IN_GAME: boolean = false;
	public static LEFT_HAND_SELECTED: boolean = false;
	public static LEVEL = 1;
	public static FINAL_SCORE = 0;
	public static GAME_SETTINGS = { timeToAcceptHandPose: 0.5, handSpellSlowdown: 1, letters: null, words: null };
}
