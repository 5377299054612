import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class LevelStartScreen extends AbstractView {
	private _animatableElements = [];
	private _headline: HTMLDivElement;
	private _subHeadline: HTMLDivElement;
	private _circleTop: HTMLDivElement;
	private _circleBottom: HTMLDivElement;
	private _circles: HTMLDivElement;
	private _backButton: HTMLDivElement;

	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this.element = element;

		Globals.HAND_SCENE.animateBackgroundColor(0xffe090);

		this._circles = element.querySelector('.circles');
		this._circleBottom = element.querySelector('.circleBottom');
		this._circleTop = element.querySelector('.circleTop');
		gsap.set(this._circles, { rotation: 45, scale: 0.2 });
		gsap.set(this._circleTop, { x: -500, scale: 0.2 });
		gsap.set(this._circleBottom, { x: 500, scale: 0.2 });

		this._backButton = element.querySelector('.backArrow');
		this._backButton.addEventListener('mouseover', this.mouseOverBack);
		this._backButton.addEventListener('mouseout', this.mouseOutBack);
		this._backButton.addEventListener('click', this.clickBack);

		this._headline = element.querySelector('.headline');
		this._subHeadline = element.querySelector('.subHeader');

		this._headline.innerHTML = 'Level ' + Globals.LEVEL;

		if (Globals.LEVEL === 2) {
			this._subHeadline.innerHTML =
				'We are making it a bit more difficult by adding the letters D,F,K,R,S,I,T to your vocabulary.<br><br>When you start the game look at the hand and copy the handshape with your own hand.';
		} else if (Globals.LEVEL === 3) {
			this._subHeadline.innerHTML = 'Ready to learn even more letters, this time G,H,M,N,X<br><br>When you start the game look at the hand and copy the handshape with your own hand.';
		} else if (Globals.LEVEL === 4) {
			this._subHeadline.innerHTML =
				'In this level we will add 2 letters where you have to turn your hand downwards, P, Q.<br>And Z and J that requires motion.<br><br>When you start the game look at the hand and copy the handshape with your own hand.';
		}

		gsap.set(this._headline, { y: window.innerHeight * -1 });
		gsap.set(this._subHeadline, { y: window.innerHeight * -1 });
		var buttonWebcam: HTMLDivElement = element.querySelector('.buttonWebcam');
		var buttonStartGame: HTMLDivElement = element.querySelector('.buttonStartLevel');

		if (Globals.HAS_ALLOWED_WEBCAM === false) {
			buttonWebcam._type = 'webcam';
			buttonWebcam.addEventListener('mouseover', this.mouseOverHandSelect);
			buttonWebcam.addEventListener('mouseout', this.mouseOutHandSelect);
			buttonWebcam.addEventListener('click', this.allowWebcam);
			gsap.set(buttonWebcam, { x: window.innerWidth * -1 });
			this._animatableElements.push(buttonWebcam);
		} else {
			buttonStartGame.style.display = 'block';
			buttonWebcam.style.display = 'none';
			buttonStartGame._type = 'startGame';
			buttonStartGame.addEventListener('mouseover', this.mouseOverHandSelect);
			buttonStartGame.addEventListener('mouseout', this.mouseOutHandSelect);
			buttonStartGame.addEventListener('click', this.clickHand);
			gsap.set(buttonStartGame, { x: window.innerWidth * -1 });
			this._animatableElements.push(buttonStartGame);
		}

		console.log('here');
	}

	private mouseOverBack = event => {
		var getTarget = event.currentTarget;
		//.children[0].children[0].children[0]
		gsap.to(getTarget, { duration: 0.2, scale: 1.2, ease: 'power1.inOut' });
	};

	private mouseOutBack = event => {
		var getTarget = event.currentTarget;
		gsap.to(getTarget, { duration: 0.2, scale: 1, ease: 'power1.inOut' });
	};

	private clickBack = () => {
		Globals.VIEW_MANAGER.setPath('chooseLevel');
	};

	private mouseOverHandSelect = event => {
		var getHand = Globals.HAND_SCENE.getHand();

		var target = event.currentTarget;

		if (target._type == 'webcam') {
			// W
			Globals.HAND_SCENE.setNewHand(23);
		} else {
			// S
			Globals.HAND_SCENE.setNewHand(19);
		}

		var getTargetPos = target.getBoundingClientRect();

		var moveTo = Globals.HAND_SCENE.getPositionIn3D(getTargetPos.x, getTargetPos.y + 100);

		//	gsap.to(getHand.position, { duration: 0.2, y: moveTo.y, ease: 'power1.inOut' });
		//	gsap.to(getHand.rotation, {duration: 0.2, x: 3.14159265 / 1.6, ease: 'power1.inOut'});

		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');
		gsap.to(getInnerBG, { duration: 0.2, x: -4, ease: 'power1.in' });
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#683AFF' });
	};

	private mouseOutHandSelect = event => {
		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');

		var getHand = Globals.HAND_SCENE.getHand();

		//	gsap.to(getHand.position, {duration: 0.2, y: 0, ease: 'power1.easeOut'});

		//gsap.to(getHand.rotation, {duration: 0.2, x: 0, ease: 'power1.easeOut'});

		Globals.HAND_SCENE.setNewHand(1);

		//gsap.to(target, { duration: 0.1, backgroundColor: '#ffe090', ease: 'power1.in' });
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#FFF6DF' });

		gsap.to(getInnerBG, { duration: 0.2, x: -360, ease: 'power1.out' });
	};

	private clickHand = event => {
		Globals.VIEW_MANAGER.setPath('game');
	};

	private allowWebcam = event => {
		Globals.SETUP_VIDEO.requestCameraAccess(this.onCameraAccessGiven);
	};

	private onCameraAccessGiven = () => {
		Globals.VIEW_MANAGER.setPath('game');
	};

	public onRequestAnimationFrame = () => {};

	public in = () => {
		Globals.HAND_SCENE.stopSpelling();

		Globals.MAIN.showTopBar('#683AFF', false);

		Globals.HAND_SCENE.changeHandColor(0xa89262);

		gsap.to(this._headline, { duration: 0.4, delay: 0.5, y: 0, ease: 'power2.out' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.4, y: 0, ease: 'power2.out' });
		gsap.to(this._animatableElements.reverse(), { delay: 0.4, duration: 0.4, x: 0, stagger: 0.1, ease: 'power2.out', onComplete: this.animatedIn });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: 0, ease: 'power2.out' });

		gsap.to(this._circles, { duration: 1.4, rotation: 0, scale: 1, ease: 'power1.out' });
		gsap.to(this._circleTop, { duration: 1.4, delay: 0, x: -0, scale: 1, ease: 'power1.out' });
		gsap.to(this._circleBottom, { duration: 1.4, delay: 0, x: 0, scale: 1, ease: 'power1.out' });

		Globals.VIEW_MANAGER.addView(this.element);
		Globals.ACTIVE_VIEW = this;
	};

	private animatedIn = () => {
		this.inComplete();
	};

	public out = () => {
		for (var i = 0; i < this._animatableElements.length; i++) {
			var getElement = this._animatableElements[i];
			getElement.removeEventListener('mouseover', this.mouseOverHandSelect);
			getElement.removeEventListener('mouseout', this.mouseOutHandSelect);
			getElement.removeEventListener('click', this.clickHand);
		}

		this._backButton.removeEventListener('mouseover', this.mouseOverBack);
		this._backButton.removeEventListener('mouseout', this.mouseOutBack);
		this._backButton.removeEventListener('click', this.clickBack);

		gsap.to(this._headline, { duration: 0.4, delay: 0.0, y: window.innerHeight * -1, ease: 'power2.in' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.1, y: window.innerHeight * -1, ease: 'power2.in', onComplete: this.outComplete });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: -100, ease: 'power2.out' });

		gsap.to(this._circles, { duration: 0.4, rotation: -45, scale: 0.2, ease: 'power2.in' });
		gsap.to(this._circleTop, { duration: 0.4, delay: 0, x: -0, scale: 0.2, ease: 'power2.in' });
		gsap.to(this._circleBottom, { duration: 0.4, delay: 0, x: 0, scale: 0.2, ease: 'power2.in' });

		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { duration: 0.4, y: 0, x: 0, ease: 'power1.inOut' });
		gsap.to(this._animatableElements, { duration: 0.4, x: window.innerWidth, stagger: 0.1, ease: 'power2.in' });

		//Globals.HAND_SCENE.animateBackgroundColor(0xfff6df);
	};

	kill() {
		super.kill();
	}
}
