import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class AboutView extends AbstractView {
	private _animatableElements = [];
	private _headline: HTMLDivElement;
	private _subHeadline: HTMLDivElement;
	private _backButton: HTMLDivElement;

	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this.element = element;

		this._headline = element.querySelector('.headline');
		this._subHeadline = element.querySelector('.subHeader');

		this._backButton = element.querySelector('.backArrow');
		this._backButton.addEventListener('mouseover', this.mouseOverBack);
		this._backButton.addEventListener('mouseout', this.mouseOutBack);
		this._backButton.addEventListener('click', this.clickBack);

		gsap.set(this._headline, { y: window.innerHeight * 1 });
		gsap.set(this._subHeadline, { y: window.innerHeight * 1 });
	}

	public onRequestAnimationFrame = () => {};

	private mouseOverBack = event => {
		var getTarget = event.currentTarget;
		//.children[0].children[0].children[0]
		gsap.to(getTarget, { duration: 0.2, scale: 1.2, ease: 'power1.inOut' });
	};

	private mouseOutBack = event => {
		var getTarget = event.currentTarget;
		gsap.to(getTarget, { duration: 0.2, scale: 1, ease: 'power1.inOut' });
	};

	private clickBack = () => {
		Globals.VIEW_MANAGER.setPath('chooseLevel');
	};

	public in = () => {
		Globals.HAND_SCENE.stopSpelling();
		Globals.MAIN.showTopBar('#683AFF', true);
		Globals.HAND_SCENE.changeHandColor(0xa89262);
		Globals.HAND_SCENE.animateBackgroundColor(0xfff6df);

		/*var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, {duration: 0.3, ease: 'power1.inOut', z: -100});*/

		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: 0, ease: 'power2.out' });
		gsap.to(this._headline, { duration: 0.4, delay: 0.2, y: 0, ease: 'power2.out' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.5, y: 0, ease: 'power2.out', onComplete: this.animatedIn });

		Globals.VIEW_MANAGER.addView(this.element);
		Globals.ACTIVE_VIEW = this;
	};

	private animatedIn = () => {
		this.inComplete();
	};

	public out = () => {
		gsap.to(this._headline, { duration: 0.4, delay: 0.1, y: window.innerHeight, ease: 'power2.in', onComplete: this.outComplete });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.0, y: window.innerHeight, ease: 'power2.in' });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: -100, ease: 'power2.out' });

		this._backButton.removeEventListener('mouseover', this.mouseOverBack);
		this._backButton.removeEventListener('mouseout', this.mouseOutBack);
		this._backButton.removeEventListener('click', this.clickBack);

		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { duration: 0.4, y: 0, x: 0, ease: 'power1.inOut' });
	};

	kill() {
		super.kill();
	}
}
