import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';

export class GameView extends AbstractView {
	private _letterDetected: HTMLDivElement;
	private _cardImage: HTMLImageElement;
	private _lookingForLetter: object;
	private _timerInterval;
	private _usingKeyboardForDebug: boolean = false;

	private _nextLetterOverlay: HTMLDivElement;
	private _overlayWord: HTMLDivElement;
	private _overlayWordCount: HTMLDivElement;
	private _progressBar: HTMLDivElement;
	private _rightSideBG: HTMLDivElement;
	private _rightSide: HTMLDivElement;

	private _previousLetter: string = null;

	private _hasFoundLetter: boolean = false;

	private _letterToFindBig: HTMLDivElement;

	private _allowFingerDescription: boolean = false;

	private _fingerDescription: HTMLDivElement;
	private _thumb: HTMLDivElement;
	private _index: HTMLDivElement;
	private _middle: HTMLDivElement;
	private _ring: HTMLDivElement;
	private _pinky: HTMLDivElement;

	private _wordArea: HTMLDivElement;
	private _currentWord: string;
	private _wordArray: any;
	private _wordSpanArray: any = [];
	private _currentLetterNumber: number;

	private _lastDetectionBaseXPos: number = 0;
	private _sameLetterInARow: boolean = false;

	private _sameLetterNoticeElement: HTMLDivElement;

	private _currentHandRotation: string;
	private _turnHandToTheSideElement: HTMLDivElement;
	private _turnHandUpWardsElement: HTMLDivElement;
	private _turnHandDownWardsElement: HTMLDivElement;
	private _getPercentageCorrectElement: HTMLDivElement;
	private _percentageMatchElement: HTMLDivElement;

	private _helloThere: HTMLDivElement;

	private _score: HTMLDivElement;
	private _scoreNumber: HTMLDivElement;
	private _time: HTMLDivElement;

	private _videoCanvasContainer: HTMLDivElement;
	private _canvasOverlayContainer: HTMLDivElement;

	private _backButton: HTMLDivElement;

	private _gameObject = {
		score: 0,
		updateScore: 0,
		seconds: 0,
		lettersPerSecond: 0,
		letterCount: 0,
		wordCount: 0,
		totalWords: 0,
		gameState: 'lookingForHand',
		secondsSinceWordStarted: 0,
		secondsSinceLetterStarted: 0
	};

	private _percentageCorrect = { count: 0 };

	private _checkForNewLetter: boolean = true;

	private _foundMatches = { thumb: false, index: false, middle: false, ring: false, pinky: false };

	private _lookForRaisedHand: boolean = true;

	private _firstRun: boolean = true;

	private _skipLetterButton: HTMLDivElement;
	private _skipLetterClicked: boolean = false;

	private _letterRequiresMotion: boolean = false;
	private _lookForMotion: boolean = false;
	private _lookForMotionStep: number = 0;
	private _motionArray: Array<number>;

	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);

		Globals.HAND_SCENE.stopSpelling();

		this.element = element;

		this._nextLetterOverlay = element.querySelector('.nextLetterOverlay');
		this._progressBar = this._nextLetterOverlay.querySelector('.progressBar');
		this._overlayWord = this._nextLetterOverlay.querySelector('.word');
		this._overlayWordCount = this._nextLetterOverlay.querySelector('.wordCount');

		this._helloThere = element.querySelector('.helloThere');
		gsap.set(this._helloThere, { opacity: 0, y: -200 });
		this._letterDetected = element.querySelector('#letterDetected');
		this._cardImage = element.querySelector('.card');
		this._wordArea = element.querySelector('.findWord');
		this._sameLetterNoticeElement = element.querySelector('.moveHandLeftOrRight');
		this._letterToFindBig = element.querySelector('.letterToFind');

		this._getPercentageCorrectElement = this.element.querySelector('.percentageCorrect');
		this._percentageMatchElement = this.element.querySelector('.percentageMatch');
		gsap.set(this._percentageMatchElement, { opacity: 0 });
		gsap.set(this._getPercentageCorrectElement, { opacity: 0 });

		this._turnHandToTheSideElement = element.querySelector('.turnHandToTheSide');
		this._turnHandDownWardsElement = element.querySelector('.turnHandDownWards');
		this._turnHandUpWardsElement = element.querySelector('.turnHandUpWards');

		this._fingerDescription = element.querySelector('.fingerDescription');
		console.log(this._fingerDescription);
		this._thumb = this._fingerDescription.querySelector('.thumb');
		this._index = this._fingerDescription.querySelector('.index');
		this._middle = this._fingerDescription.querySelector('.middle');
		this._ring = this._fingerDescription.querySelector('.ring');
		this._pinky = this._fingerDescription.querySelector('.pinky');

		this._score = element.querySelector('.score');
		this._scoreNumber = element.querySelector('.scoreNumber');
		this._time = element.querySelector('.time');
		gsap.set(this._score, { y: -60 });
		gsap.set(this._time, { y: -40 });

		this._rightSideBG = element.querySelector('.rightSideBG');
		this._rightSide = element.querySelector('.rightSide');

		this._backButton = element.querySelector('.backArrow');
		this._backButton.addEventListener('mouseover', this.mouseOverBack);
		this._backButton.addEventListener('mouseout', this.mouseOutBack);
		this._backButton.addEventListener('click', this.clickBack);

		this._videoCanvasContainer = element.querySelector('.videoCanvasContainer');
		var getVideoCanvas = Globals.HAND_POSE_DETECTION.getVideoCanvas();
		this._videoCanvasContainer.appendChild(getVideoCanvas);

		this._canvasOverlayContainer = element.querySelector('.canvasOverlayContainer');
		var getCanvasOverlay = Globals.HAND_POSE_DETECTION.getCanvasOverlay();
		this._canvasOverlayContainer.appendChild(getCanvasOverlay);

		// Settings for each level:
		Globals.IN_GAME = true;
		Globals.GAME_SETTINGS.handSpellSlowdown = 1;
		Globals.GAME_SETTINGS.timeToAcceptHandPose = 0.1;
		console.log('Globals.LEVEL ; ' + Globals.LEVEL);
		if (Globals.LEVEL === 1) {
			Globals.GAME_SETTINGS.letters = Words.lettersLevel1;
			Globals.GAME_SETTINGS.words = Words.wordsLevel1;
		} else if (Globals.LEVEL === 2) {
			Globals.GAME_SETTINGS.letters = Words.lettersLevel2;
			Globals.GAME_SETTINGS.words = Words.wordsLevel2;
			//	Globals.GAME_SETTINGS.handSpellSlowdown = 0.8;
			//	Globals.GAME_SETTINGS.timeToAcceptHandPose = 0.075;
		} else if (Globals.LEVEL === 3) {
			Globals.GAME_SETTINGS.letters = Words.lettersLevel3;
			Globals.GAME_SETTINGS.words = Words.wordsLevel3;
			//Globals.GAME_SETTINGS.handSpellSlowdown = 0.5;
			//Globals.GAME_SETTINGS.timeToAcceptHandPose = 0.05;
		} else if (Globals.LEVEL === 4) {
			Globals.GAME_SETTINGS.letters = Words.lettersLevel4;
			Globals.GAME_SETTINGS.words = Words.wordsLevel4;
			//Globals.GAME_SETTINGS.handSpellSlowdown = 0.3;
			//Globals.GAME_SETTINGS.timeToAcceptHandPose = 0.0;
		}
		if (Globals.HAS_ALLOWED_WEBCAM !== true) {
			Globals.SETUP_VIDEO.requestCameraAccess(this.onCameraAccessGiven);
		}

		if (Globals.DEBUG_TURN_OFF_HANDPOSE) {
			document.body.addEventListener('click', this.letterMatch);
		}

		if (Globals.ALLOW_TO_TEST_WITH_KEYBOARD === true) {
			document.body.addEventListener('keypress', this.keyUp);
		}

		this._skipLetterButton = element.querySelector('.skipLetterButton');
		this._skipLetterButton.addEventListener('click', this.clickSkipLetter);
		gsap.set(this._skipLetterButton, { y: 150 });

		if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
			this._percentageMatchElement.style.left = 'auto';
			this._percentageMatchElement.style.right = 20 + 'px';
			this._getPercentageCorrectElement.style.left = 'auto';
			this._getPercentageCorrectElement.style.right = 20 + 'px';
			//gsap.set(this._percentageMatchElement, { x: 200 });
			//gsap.set(this._getPercentageCorrectElement, { x: 200 });

			this._letterToFindBig.style.left = '0px';
			//	this._letterToFindBig.style.right = 'calc(50% + 30px)';

			this._rightSide.style.left = '0%';
			var getContentSide: HTMLDivElement = element.querySelector('.contentSide');
			getContentSide.style.left = '50%';
		}
	}

	private keyUp = event => {
		this._usingKeyboardForDebug = true;
		var getKey = String.fromCharCode(event.keyCode);
		console.log('getKey : ' + getKey);
		var newWord = getKey;
		this.setWord(newWord);
	};

	private mouseOverBack = event => {
		var getTarget = event.currentTarget;
		//.children[0].children[0].children[0]
		gsap.to(getTarget, { duration: 0.2, scale: 1.2, ease: 'power1.inOut' });
	};

	private mouseOutBack = event => {
		var getTarget = event.currentTarget;
		gsap.to(getTarget, { duration: 0.2, scale: 1, ease: 'power1.inOut' });
	};

	private clickBack = () => {
		Globals.VIEW_MANAGER.setPath('chooseLevel');
	};

	private clickSkipLetter = event => {
		this._skipLetterClicked = true;

		window.ga('send', 'event', 'letter skipped', 'click', this._lookingForLetter.letter.toUpperCase());

		this.letterMatch();
	};

	private onCameraAccessGiven = () => {
		console.log('got camera');
		gsap.delayedCall(0, Globals.HAND_POSE_DETECTION.allowHandAnalysis);
	};

	private startTimer = () => {
		this._timerInterval = setInterval(this.progressTime, 1000);
	};

	private progressTime = () => {
		this._gameObject.seconds = this._gameObject.seconds + 1;
		this._gameObject.secondsSinceWordStarted = this._gameObject.secondsSinceWordStarted + 1;
		this._gameObject.secondsSinceLetterStarted = this._gameObject.secondsSinceLetterStarted + 1;
		var minutes = Math.floor(this._gameObject.seconds / 60);
		var seconds = this._gameObject.seconds % 60;
		this._time.innerHTML = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
		//	this.updateLettersPerMinute();
	};

	private updateScore = () => {
		//var calculateLettersPerMinute = (this._gameObject.letterCount / this._gameObject.secondsSinceWordStarted) * 60;
		//	var rounded = calculateLettersPerMinute.toFixed(2);

		var addToScore = 10 - this._gameObject.secondsSinceWordStarted;
		if (addToScore < 0) {
			addToScore = 1;
		}

		window.ga('send', 'event', 'letter guessed', 'automatic', this._lookingForLetter.letter.toUpperCase(), this._gameObject.secondsSinceWordStarted);

		if (this._skipLetterClicked) {
			addToScore = 0;
		}
		this._skipLetterClicked = false;

		this._gameObject.score = this._gameObject.score + addToScore;
		gsap.to(this._gameObject, {
			delay: 0.1,
			duration: 0.5,
			updateScore: this._gameObject.score,
			onStart: this.onStartUpdateScore,
			onUpdate: this.onUpdateScore,
			onComplete: this.onCompleteUpdateScore
		});
		Globals.FINAL_SCORE = this._gameObject.score;
		/*if (rounded == '1.0') {
			this._score.innerHTML = rounded + ' letter per minute';
		} else {
			this._score.innerHTML = rounded + ' letters per minute';
		}*/
		//	console.log('--- rounded : ' + this._gameObject.score);
	};

	private onStartUpdateScore = () => {
		gsap.to(this._score, { duration: 0.3, fontSize: '30px', color: '#683aff', ease: 'power2.out' });
	};

	private onCompleteUpdateScore = () => {
		gsap.to(this._score, { duration: 0.3, fontSize: '20px', color: '#fff6df', ease: 'power2.in' });
	};

	private onUpdateScore = () => {
		var newScore = this._gameObject.score;
		this._scoreNumber.innerHTML = String(Math.round(this._gameObject.updateScore));
	};

	private showNextLetterOverlay = (word, wordCount, totalWords) => {
		//gsap.set(this._nextLetterOverlay, { duration: 0.3, x: 0, width: 0, ease: 'power1.out' });
		gsap.to(this._progressBar, { scaleX: 0, ease: 'power1.in' });

		this._nextLetterOverlay.style.display = 'block';

		this._overlayWord.innerHTML = word;
		this._overlayWordCount.innerHTML = 'Word ' + wordCount + ' of ' + totalWords + '';

		gsap.to(this._nextLetterOverlay, { duration: 1, clip: 'rect(0px 800px 500px 0px)', ease: 'power1.out' });

		gsap.set(this._progressBar, { duration: 0.1, y: 0, scaleX: 0, ease: 'power1.in' });
		gsap.to(this._progressBar, { duration: 1.5, delay: 1, scaleX: 1, ease: 'power1.in', onComplete: this.hideNextLetterOverlay });
		//
		// Reset percentages
		if (this._lookingForLetter && this._lookingForLetter.thumb) {
			this._lookingForLetter.thumb.percentageCorrect = 0;
			this._lookingForLetter.index.percentageCorrect = 0;
			this._lookingForLetter.middle.percentageCorrect = 0;
			this._lookingForLetter.ring.percentageCorrect = 0;
			this._lookingForLetter.thumb.percentageCorrect = 0;
		}
	};

	private hideNextLetterOverlay = () => {
		gsap.to(this._nextLetterOverlay, { duration: 0.3, clip: 'rect(0px 400px 500px 400px)', ease: 'power1.in', onComplete: this.fullyHideNextLetterOverlay });
		//gsap.to(this._progressBar, { duration: 0.1, y: 20, ease: 'power1.in' });
	};

	private fullyHideNextLetterOverlay = () => {
		this._nextLetterOverlay.style.display = 'none';
		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.rotation, { duration: 0.2, z: 0, ease: 'power2.inOut' });
		var newX = -0.35;
		if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
			newX = 0.35;
		}
		gsap.to(getHand.position, { duration: 0.5, x: newX, z: 2, ease: 'power2.out' });
		this.setNewLetter();
		this._checkForNewLetter = true;

		gsap.to(this._wordSpanArray, { duration: 0.4, ease: 'power2.in', y: 0, stagger: 0.05 });
		gsap.to(this._skipLetterButton, { duration: 0.3, ease: 'power2.in', y: 0 });

		// Show time and score
		if (this._firstRun) {
			this._firstRun = false;
			gsap.to(this._time, { duration: 0.2, y: 0, ease: 'power1.out' });
			gsap.to(this._score, { duration: 0.2, delay: 0.1, y: 0, ease: 'power1.out' });
			this.startTimer();
		}
		this._gameObject.secondsSinceWordStarted = 0;
	};

	private setWord = forceWord => {
		this._checkForNewLetter = false;

		var length = this._wordSpanArray.length;
		for (var p = 0; p < length; p++) {
			var currentLetter = this._wordSpanArray[p];
			this._wordArea.removeChild(currentLetter);
		}

		this._currentLetterNumber = 0;
		this._wordSpanArray = [];
		// [a,b,c,d,e,f,i,k,l,m,n];
		//	var randomWords = Words.wordsCurated;
		//	randomWords = ['DEFABC'];

		var getWords = Globals.GAME_SETTINGS.words;
		this._gameObject.totalWords = Globals.GAME_SETTINGS.words.length;

		if (this._usingKeyboardForDebug === false && this._gameObject.wordCount === this._gameObject.totalWords) {
			Globals.VIEW_MANAGER.setPath('levelCompleted');
		} else {
			if (this._usingKeyboardForDebug) {
				this._previousLetter = 'æ';
			} else {
				this._currentWord = getWords[this._gameObject.wordCount];
			}

			if (forceWord) {
				this._currentWord = forceWord;
			}
			this._wordArray = this._currentWord.split('');

			for (var i = 0; i < this._wordArray.length; i++) {
				var createSpan = document.createElement('span');
				createSpan.innerHTML = this._wordArray[i];
				createSpan.style.opacity = '0.3';
				createSpan.style.display = 'inline-block';
				gsap.set(createSpan, { y: 150 });
				this._wordArea.appendChild(createSpan);
				this._wordSpanArray.push(createSpan);
			}
			this._gameObject.wordCount++;
			if (this._usingKeyboardForDebug === true) {
				this.setNewLetter(this._currentWord);
			} else {
				this.showNextLetterOverlay(this._currentWord, this._gameObject.wordCount, this._gameObject.totalWords);
			}
		}
	};

	private foundRaisedHand = () => {
		console.log('found raised hand');
		this._gameObject.gameState = 'raisedHandFound';
		gsap.to(this._helloThere, { duration: 0.3, opacity: 0, y: 100, ease: 'power2.in' });
		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.rotation, { duration: 0.3, z: 0, ease: 'power1.inOut' });
		//this.setWord();
		gsap.delayedCall(0.5, this.setWord);
	};

	private setNewLetter = () => {
		if (this._currentLetterNumber > this._wordArray.length - 1) {
			//	Globals.HAND_SCENE.setNewHand(0);
			var getHand = Globals.HAND_SCENE.getHand();
			//	gsap.to(getHand.position, { delay: 0, duration: 0.3, x: 0, z: 2, ease: 'power1.inOut' });
			gsap.to(this._wordSpanArray, { duration: 0.2, ease: 'power2.in', y: 150, stagger: 0.02 });
			gsap.to(this._skipLetterButton, { duration: 0.1, ease: 'power2.in', y: 150 });

			var newX = -200;
			if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
				newX = 200;
			}

			gsap.to(this._percentageMatchElement, { duration: 0.3, delay: 0.3, x: 0, opacity: 0, ease: 'power1.in' });
			this._checkForNewLetter = false;
			gsap.delayedCall(0.4, this.setWord);
			//	this.setWord();
			return;
		} else {
			/*	gsap.to(this._getPercentageCorrectElement, {duration: 0.1, delay: 0.2, x: -200, ease: 'power1.in'});
		gsap.to(this._percentageMatchElement, {duration: 0.1, delay: 0.2, x: 0, ease: 'power1.out'});*/
			this._percentageCorrect.count = 0;
			this._getPercentageCorrectElement.innerHTML = '0%';
			gsap.to(this._getPercentageCorrectElement, { duration: 0.3, delay: 0.3, x: 0, opacity: 1, ease: 'power1.out' });
			var newX = -200;
			if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
				newX = 200;
			}
			gsap.to(this._percentageMatchElement, { duration: 0.3, delay: 0.3, x: 0, opacity: 0, ease: 'power1.in' });
			this._hasFoundLetter = false;

			gsap.killTweensOf(this._fingerDescription);
			this._allowFingerDescription = false;
			gsap.to(this._fingerDescription, { duration: 0.5, y: 200, ease: 'power1.in' });
			gsap.to(this._fingerDescription, { duration: 0.5, delay: 5, y: 0, ease: 'power1.out', onComplete: this.allowfingerDescriptions });
		}

		if (this._lookingForLetter) {
			this._previousLetter = this._lookingForLetter.letter;
		}

		this._lookingForLetter = Globals.ALPHABET.getSpecificLetter(this._wordArray[this._currentLetterNumber]);
		if (this._previousLetter === this._lookingForLetter.letter && this._usingKeyboardForDebug === false) {
			this._sameLetterInARow = true;
			gsap.to(this._sameLetterNoticeElement, { duration: 0.2, y: -40 });
		} else {
			this._sameLetterInARow = false;
			gsap.to(this._sameLetterNoticeElement, { duration: 0.2, y: 0 });
		}
		this._lookForMotionStep = 0;

		if (this._lookingForLetter.letter === 'z' || this._lookingForLetter.letter === 'j') {
			this._letterRequiresMotion = true;
			this._lookForMotion = false;
		} else {
			this._letterRequiresMotion = false;
			this._lookForMotion = false;
		}

		//	this._lookingForLetter = Globals.ALPHABET.getSpecificLetter('n');

		gsap.to(this._wordSpanArray[this._currentLetterNumber - 1], 0.2, { opacity: 0.3, fontSize: '77px', ease: 'power1.inOut' });
		gsap.to(this._wordSpanArray[this._currentLetterNumber], 0.2, { opacity: 1, fontSize: '77px', ease: 'power1.inOut' });

		Globals.HAND_SCENE.setNewHand(Globals.HAND_SCENE.convertLetterToNumber(this._lookingForLetter.letter));

		var getHand = Globals.HAND_SCENE.getHand();
		var newX = -0.35;
		if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
			newX = 0.35;
		}
		gsap.to(getHand.position, { duration: 0.5, x: newX, z: 2, ease: 'power2.out' });

		this._letterToFindBig.innerHTML = this._lookingForLetter.letter.toUpperCase();
		this.updateFingerDescriptionCard(this._lookingForLetter);
		this._checkForNewLetter = true;
		this._gameObject.secondsSinceLetterStarted = 0;
		this._currentLetterNumber++;
	};

	private updateFingerDescriptionCard = alphabetObject => {
		this._thumb.children[0].innerHTML = 'Thumb: ' + alphabetObject.thumb.helpText;
		this._index.children[0].innerHTML = 'Index: ' + alphabetObject.index.helpText;
		this._middle.children[0].innerHTML = 'Middle: ' + alphabetObject.middle.helpText;
		this._ring.children[0].innerHTML = 'Ring: ' + alphabetObject.ring.helpText;
		this._pinky.children[0].innerHTML = 'Pinky: ' + alphabetObject.little.helpText;
	};

	public handLost = () => {};

	private reactToHandPosChanges = points => {
		var x = points[0];
		var y = points[1];

		//	console.log('x : ' + x);
		//	console.log('y : ' + y);

		if (this._lookingForLetter.letter === 'z') {
			if (this._lookForMotionStep === 0) {
				this._motionArray.push(points);
				// Detect if a rightward motion is made
				// FIXME: Needs to be leftward for Lefthanded ? (or no.. the same, right?)
				var _length = this._motionArray.length;
				var _skipBackTo = _length - 10;
				if (_skipBackTo < 0) {
					_skipBackTo = 0;
				}

				//	console.log('_skipBackTo : ' + _skipBackTo)
				//	console.log('_length : ' + _length)
				var getLowestX = 1;
				var getHighestX = 0;
				for (var i = _length - 1; i > _skipBackTo; i--) {
					var getX = this._motionArray[i][0];
					if (getX < getLowestX) {
						getLowestX = getX;
					}
					if (getX > getHighestX) {
						getHighestX = getX;
					}
				}
				//console.log('getLowestX : ' + getLowestX)
				//console.log('getHighestX : ' + getHighestX)

				var difference = getLowestX - getHighestX;
				if (difference < -0.05) {
					this._motionArray = [];
					this._lookForMotionStep = 1;
				}
				console.log('Step 0 -- difference : ' + difference);
			} else if (this._lookForMotionStep === 1) {
				this._motionArray.push(points);
				// Detect if a downward motion is made
				// FIXME: Needs to be leftward for Lefthanded ? (or no.. the same, right?)
				var _length = this._motionArray.length;
				var _skipBackTo = _length - 10;
				if (_skipBackTo < 0) {
					_skipBackTo = 0;
				}

				//	console.log('_skipBackTo : ' + _skipBackTo)
				//	console.log('_length : ' + _length)
				var getLowestY = 1;
				var getHighestY = 0;
				for (var i = _length - 1; i > _skipBackTo; i--) {
					var getY = this._motionArray[i][1];
					if (getY < getLowestY) {
						getLowestY = getY;
					}
					if (getY > getHighestY) {
						getHighestY = getY;
					}
				}
				//	console.log('getLowestX : ' + getLowestY)
				//	console.log('getHighestX : ' + getHighestY)

				var difference = getLowestY - getHighestY;
				if (difference < -0.05 && difference !== 1) {
					this._motionArray = [];
					this._lookForMotionStep = 2;
				}
				console.log('Step 1 -- difference : ' + difference);
			} else if (this._lookForMotionStep === 2) {
				this._motionArray.push(points);
				// Detect if a downward motion is made
				// FIXME: Needs to be leftward for Lefthanded ? (or no.. the same, right?)
				var _length = this._motionArray.length;
				var _skipBackTo = _length - 10;
				if (_skipBackTo < 0) {
					_skipBackTo = 0;
				}

				//console.log('_skipBackTo : ' + _skipBackTo)
				//	console.log('_length : ' + _length)
				var getLowestX = 1;
				var getHighestX = 0;
				for (var i = _length - 1; i > _skipBackTo; i--) {
					var getX = this._motionArray[i][0];
					if (getX < getLowestX) {
						getLowestX = getX;
					}
					if (getX > getHighestX) {
						getHighestX = getX;
					}
				}
				//console.log('getLowestX : ' + getLowestX)
				//console.log('getHighestX : ' + getHighestX)

				var difference = getLowestX - getHighestX;
				if (difference < -0.05 && difference !== 1) {
					this._motionArray = [];
					this._lookForMotionStep = 9999;
					this.letterMatch(points);
				}
				console.log('Step 2 -- difference : ' + difference);
			}
			// To clear a step make a new array (_motionArray)
		}

		if (this._lookingForLetter.letter === 'j') {
			if (this._lookForMotionStep === 0) {
				this._motionArray.push(points);
				// Detect if a downward motion is made
				// FIXME: Needs to be leftward for Lefthanded ? (or no.. the same, right?)
				var _length = this._motionArray.length;
				var _skipBackTo = _length - 10;
				if (_skipBackTo < 0) {
					_skipBackTo = 0;
				}

				//	console.log('_skipBackTo : ' + _skipBackTo)
				//	console.log('_length : ' + _length)
				var getLowestY = 1;
				var getHighestY = 0;
				for (var i = _length - 1; i > _skipBackTo; i--) {
					var getY = this._motionArray[i][1];
					if (getY < getLowestY) {
						getLowestY = getY;
					}
					if (getY > getHighestY) {
						getHighestY = getY;
					}
				}
				//	console.log('getLowestX : ' + getLowestY)
				//	console.log('getHighestX : ' + getHighestY)

				var difference = getLowestY - getHighestY;
				if (difference < -0.05 && difference !== 1) {
					this._motionArray = [];
					this._lookForMotionStep = 1;
				}

				console.log('Step 0 -- difference : ' + difference);
			} else if (this._lookForMotionStep === 1) {
				this._motionArray.push(points);
				// Detect if a downward motion is made
				// FIXME: Needs to be leftward for Lefthanded ? (or no.. the same, right?)
				var _length = this._motionArray.length;
				var _skipBackTo = _length - 10;
				if (_skipBackTo < 0) {
					_skipBackTo = 0;
				}

				//	console.log('_skipBackTo : ' + _skipBackTo)
				//	console.log('_length : ' + _length)
				var getLowestY = 1;
				var getHighestY = 0;
				for (var i = _length - 1; i > _skipBackTo; i--) {
					var getY = this._motionArray[i][1];
					if (getY < getLowestY) {
						getLowestY = getY;
					}
					if (getY > getHighestY) {
						getHighestY = getY;
					}
				}
				//	console.log('getLowestX : ' + getLowestY)
				//	console.log('getHighestX : ' + getHighestY)

				var difference = getLowestY - getHighestY;
				if (difference < -0.05 && difference !== 1) {
					this._motionArray = [];
					this._lookForMotionStep = 9999;
					this.letterMatch(points);
				}
				console.log('Step 1 -- difference : ' + difference);
			}
		}
	};

	public reactToDOMCursor = (fingerPoseResults, result: Coords3D) => {
		if (this._lookForMotion === true) {
			this.reactToHandPosChanges(result[0]);
		}

		if (this._checkForNewLetter === false) {
			return;
		}

		//console.log(fingerPoseResults);
		//var isPalmPointing = Globals.HAND_ANALYZER.isRightHandPalmPointingTowardsYourself(result);
		//var isClicking = Globals.HAND_ANALYZER.isClicking(fingerPoseResults, result, 0);
		var fingerData = {
			handSize: 0,
			handRotation: 0
		};

		fingerData.handSize = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(result[0], result[5]) * 10;

		//	fingerData.handRotation = Globals.HAND_ANALYZER.getHandRotation(result);
		// Better way of getting the hand rotation
		fingerData.handRotation = Globals.HAND_ANALYZER.getHandRotationFromIndex(result);

		//console.log(fingerData.handRotation);

		if (this._lookForRaisedHand === true) {
			//	console.log(fingerData.handSize);
			//if (fingerData.handSize > 0.7) {

			this.foundRaisedHand();
			this._lookForRaisedHand = false;
			this._checkForNewLetter = false;
			//}

			return;
		}

		//console.log('_checkForNewLetter : ' + this._checkForNewLetter);

		// Try to make sure we dont get to many false positives
		if (fingerData.handSize < 0.7) {
			return;
		}

		//	console.log(fingerPoseResults);
		//	console.log(fingerData);

		var thumbCurl = fingerPoseResults.curls[0].fingerCurl;
		var indexFingerCurl = fingerPoseResults.curls[1].fingerCurl;
		var middleFingerCurl = fingerPoseResults.curls[2].fingerCurl;
		var ringFingerCurl = fingerPoseResults.curls[3].fingerCurl;
		var littleFingerCurl = fingerPoseResults.curls[4].fingerCurl;

		var thumbAngle = fingerPoseResults.curls[0].angle;
		var indexFingerAngle = fingerPoseResults.curls[1].angle;
		var middleFingerAngle = fingerPoseResults.curls[2].angle;
		var ringFingerAngle = fingerPoseResults.curls[3].angle;
		var littleFingerAngle = fingerPoseResults.curls[4].angle;

		//	console.log('handRotation : ' + handRotation);

		var letterDetected = '';

		var lookForLetter = this._lookingForLetter;
		//console.log(lookForLetter);

		lookForLetter.thumb.currentAngle = thumbAngle;
		lookForLetter.index.currentAngle = indexFingerAngle;
		lookForLetter.middle.currentAngle = middleFingerAngle;
		lookForLetter.ring.currentAngle = ringFingerAngle;
		lookForLetter.little.currentAngle = littleFingerAngle;

		var countCorrectFingers = 0;

		var isHandAngleCorrect = false;

		if (lookForLetter.rotation === 'up') {
			if (fingerData.handRotation < 180 && fingerData.handRotation > -180) {
				isHandAngleCorrect = true;
				gsap.to(this._turnHandUpWardsElement, { duration: 0.1, opacity: 0 });
			} else {
				gsap.to(this._turnHandToTheSideElement, { duration: 0.1, opacity: 0 });
				gsap.to(this._turnHandDownWardsElement, { duration: 0.1, opacity: 0 });
				gsap.to(this._turnHandUpWardsElement, { duration: 0.1, opacity: 1 });
			}
		}

		if (lookForLetter.rotation === 'down') {
			if (fingerData.handRotation > 80 && fingerData.handRotation < 220) {
				isHandAngleCorrect = true;
				gsap.to(this._turnHandDownWardsElement, { duration: 0.1, opacity: 0 });
			} else {
				gsap.to(this._turnHandToTheSideElement, { duration: 0.1, opacity: 0 });
				gsap.to(this._turnHandDownWardsElement, { duration: 0.1, opacity: 1 });
				gsap.to(this._turnHandUpWardsElement, { duration: 0.1, opacity: 0 });
			}
		}

		if (lookForLetter.rotation === 'side') {
			if (fingerData.handRotation > 40 && fingerData.handRotation < 140) {
				isHandAngleCorrect = true;
				gsap.to(this._turnHandToTheSideElement, { duration: 0.1, opacity: 0 });
			} else {
				gsap.to(this._turnHandToTheSideElement, { duration: 0.1, opacity: 1 });
				gsap.to(this._turnHandDownWardsElement, { duration: 0.1, opacity: 0 });
				gsap.to(this._turnHandUpWardsElement, { duration: 0.1, opacity: 0 });
			}
		}
		//og(result)
		//console.log('isHandAngleCorrect : ' + isHandAngleCorrect)
		if (isHandAngleCorrect === true) {
			for (var i = 0; i < 5; i++) {
				var lookFor;
				if (i == 0) {
					lookFor = lookForLetter.thumb;
				} else if (i == 1) {
					lookFor = lookForLetter.index;
				} else if (i == 2) {
					lookFor = lookForLetter.middle;
				} else if (i == 3) {
					lookFor = lookForLetter.ring;
				} else if (i == 4) {
					lookFor = lookForLetter.little;
				}

				var angle = lookFor.currentAngle;

				if (angle < lookFor.curlMin && angle > lookFor.curlMax) {
					lookFor.percentageCorrect = 1;

					if (lookFor.special !== 'none') {
						lookFor.percentageCorrect = 0;

						if (lookFor.special === 'betweenRingAndPointerBase') {
							// We are in this take not looking at tips _ but instead at the base of the finger

							if (result[4][0] > result[9][0] - 0.002 && result[4][0] < result[17][0] + 0.002) {
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'betweenRingAndLittleBase') {
							// Used for M
							// We are in this take not looking at tips _ but instead at the base of the finger
							if (result[4][0] > result[7][0] && result[4][0] < result[17][0]) {
								// Lets also make sure that the thumbs 'tip' is higher on the Y axis than the Tips of the fingers
								if (result[4][1] < result[16][1] && result[4][1] < result[20][1]) {
									lookFor.percentageCorrect = 1;
								}
							}
						} else if (lookFor.special === 'betweenMiddleAndRing') {
							// Used for N
							if (result[4][0] > result[10][0] - 0.001 && result[4][0] < result[14][0] + 0.001) {
								// Lets also make sure that the thumbs 'tip' is higher on the Y axis than the Tips of the fingers
								//if (result[4][1] < result[16][1] && result[4][1] < result[12][1]) {
								lookFor.percentageCorrect = 1;
								//	}
							}
						} else if (lookFor.special === 'betweenIndexAndMiddle') {
							if (result[4][0] > result[6][0] - 0.004 && result[4][0] < result[10][0] + 0.004) {
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'thumbBendOverOtherFingers') {
							//	console.log('result[4][0] ; ' + result[4][0]);
							//	console.log('result[5][0] ; ' + result[5][0]);
							//if (Globals.IS_MOBILE === true) {
							// && result[4][0] < result[17][0]
							if (result[4][0] > result[8][0] - 0.03 * fingerData.handSize) {
								lookFor.percentageCorrect = 1;
							}
							/*	}
						else {
							if (result[4][0] > result[5][0] + 0.01 * fingerData.handSize && result[4][0] < result[17][0] + 0.0 * fingerData.handSize) {
								lookFor.percentageCorrect = 1;
							}
						}*/
						} else if (lookFor.special === 'thumbBendOverOtherFingersAndUnderOtherFingers') {
							// Used for the letter E - to make sure it doesnt look too much like a T -
							if (result[4][0] > result[5][0] && result[4][0] < result[17][0]) {
								//if (result[4][1] > result[8][1] && result[4][1] > result[12][1] && result[4][1] > result[16][1]) {
								lookFor.percentageCorrect = 1;
								//}
							}
						} else if (lookFor.special === 'thumbBendOverOtherFingersAndOverOtherFingers') {
							// FIXME: NOT USED
							// Used for the letter T - to make sure it doesnt look too much like a E -
							if (result[4][0] > result[5][0] - 0.02 * fingerData.handSize && result[4][0] < result[17][0] + 0.001 * fingerData.handSize) {
								if (result[4][1] < result[8][1] && result[4][1] < result[12][1] && result[4][1] < result[16][1]) {
									lookFor.percentageCorrect = 1;
								}
							}
						} else if (lookFor.special === 'betweenIndexAndMiddleLetterT') {
							var distanceWithHandSize = 0.004 * fingerData.handSize;

							if (result[4][0] > result[7][0] - distanceWithHandSize && result[4][0] < result[10][0] + distanceWithHandSize) {
								if (result[4][1] < result[8][1] && result[4][1] < result[12][1] && result[4][1] < result[16][1]) {
									lookFor.percentageCorrect = 1;
								}
							}
						} else if (lookFor.special === 'betweenIndexAndMiddleLetterS') {
							var distanceWithHandSize = 0.004 * fingerData.handSize;

							if (result[4][0] > result[7][0] && result[4][0] < result[15][0]) {
								// Check its pos on the Y axis too
								if (result[4][1] < result[8][1] && result[4][1] < result[12][1] && result[4][1] < result[16][1]) {
									lookFor.percentageCorrect = 1;
								}
							}
						} else if (lookFor.special === 'thumbToTheLeft') {
							// Used for the letter A - make sure the thumb is all the way to the left - but also not to far from the hand
							var distanceWithHandSize = 0.03 * fingerData.handSize;
							console.log('result[4][0] : ' + result[4][0]);
							console.log('result[8][0] : ' + result[8][0]);
							if (result[4][0] < result[5][0]) {
								// && result[4][0] + distanceWithHandSize > result[8][0]
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'distanceBetweenThumbAndPointer') {
							var distancePinch = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(result[4], result[8]);
							var distanceWithHandSize = 0.02 * fingerData.handSize;

							//	console.log('distancePinch : ' + distancePinch);
							//	console.log('distanceWithHandSize : ' + distanceWithHandSize);

							if (distancePinch > distanceWithHandSize) {
								lookFor.percentageCorrect = 1;
							} else {
								lookFor.percentageCorrect = distancePinch / distanceWithHandSize;
								console.log('lookFor.percentageCorrect : ' + lookFor.percentageCorrect);
							}
						} else if (lookFor.special === 'pinchThumbAndPointer') {
							var distancePinch = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(result[4], result[8]);
							var distanceWithHandSize = 0.045 * fingerData.handSize;

							//	console.log('distancePinch : ' + distancePinch);
							//	console.log('distanceWithHandSize : ' + distanceWithHandSize);

							if (distancePinch < distanceWithHandSize) {
								lookFor.percentageCorrect = 1;
							} else {
								lookFor.percentageCorrect = distanceWithHandSize / distancePinch;
								//	console.log('lookFor.percentageCorrect : ' + lookFor.percentageCorrect);
							}
						} else if (lookFor.special === 'thumbPointerAlignOnYAxis') {
							var distancePointerAndThumbOnY = result[4][1] - result[8][1];
							if (distancePointerAndThumbOnY < 0) {
								distancePointerAndThumbOnY = distancePointerAndThumbOnY * -1;
							}

							//	console.log('distancePointerAndThumbOnY : ' + distancePointerAndThumbOnY);

							if (distancePointerAndThumbOnY < 0.07) {
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'crossIndexAndMiddle') {
							// Used on R
							var distanceIndexAndMiddle = result[12][0] - result[8][0];
							if (distanceIndexAndMiddle < 0.0 + 0.01 * fingerData.handSize) {
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'indexAndMiddleMustBeClose') {
							// Used on U
							var distanceIndexAndMiddle = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(result[12], result[8]);
							var distanceWithHandSize = 0.045 * fingerData.handSize;
							//	console.log('distanceIndexAndMiddle : ' + distanceIndexAndMiddle);
							//	console.log('distanceWithHandSize : ' + distanceWithHandSize);
							if (distanceIndexAndMiddle < distanceWithHandSize) {
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'indexAndMiddleMustBeApart') {
							// Used on V
							var distanceIndexAndMiddle = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(result[12], result[8]);
							var distanceWithHandSize = 0.02 * fingerData.handSize;
							//	console.log('distanceIndexAndMiddle : ' + distanceIndexAndMiddle);
							//	console.log('distanceWithHandSize : ' + distanceWithHandSize);
							if (distanceIndexAndMiddle > distanceWithHandSize) {
								lookFor.percentageCorrect = 1;
							}
						} else if (lookFor.special === 'indexAndMiddleAndRingMustBeApart') {
							// Used on W
							var distanceIndexAndMiddle = Globals.HAND_ANALYZER.findDistanceBetweenTwoLandMarks(result[16], result[12]);
							var distanceWithHandSize = 0.015 * fingerData.handSize;

							//	console.log('distanceIndexAndMiddle : ' + distanceIndexAndMiddle);
							//	console.log('distanceWithHandSize : ' + distanceWithHandSize);
							if (distanceIndexAndMiddle > distanceWithHandSize) {
								lookFor.percentageCorrect = 1;
							} else {
								lookFor.percentageCorrect = (distanceIndexAndMiddle - 0.01) / distanceWithHandSize;
								//		console.log('lookFor.percentageCorrect : ' + lookFor.percentageCorrect);
							}
						}
					}
				} else {
					if (angle > lookFor.curlMin) {
						// Needs more bend
						//console.log('lookForLetter.index.curlMin : ' + lookFor.curlMin)

						// 60 / 180
						var maxDistance = 180 - lookFor.curlMin; // 120 (is also the max distance
						var calculateExactDistance = angle - lookFor.curlMin; // 60 - 180
						lookFor.percentageCorrect = (maxDistance - calculateExactDistance) / maxDistance;

						if (i === 1) {
							/*	console.log('angle : ' + angle);
							console.log('maxDistance : ' + maxDistance);
							console.log('calculateExactDistance : ' + calculateExactDistance);
							console.log('Angle Over : ' + lookFor.percentageCorrect);*/
						}
						//	lookFor.percentageCorrect = 0;
					} else if (angle < lookFor.curlMax) {
						// Needs to be bend less
						//indexPercentageCorrect = indexFingerAngle / lookForLetter.index.curlMax;
						lookFor.percentageCorrect = angle / lookFor.curlMax;
						//	lookFor.percentageCorrect = 0;
						if (i === 1) {
							//console.log('Angle Less : ' + lookFor.percentageCorrect);
						}
					}
				}

				/*	if (i === 4) {
				console.log('lookFor.percentageCorrect : ' + lookFor.percentageCorrect)
			}*/

				if (lookFor.percentageCorrect === 1) {
					countCorrectFingers = 1 + countCorrectFingers;
				}
			}
		} else {
			// IF the hand rotation is wrong - no fingers are correct
			lookForLetter.thumb.percentageCorrect = 0;
			lookForLetter.index.percentageCorrect = 0;
			lookForLetter.middle.percentageCorrect = 0;
			lookForLetter.ring.percentageCorrect = 0;
			lookForLetter.thumb.percentageCorrect = 0;
		}

		//	console.log('lookForLetter.index.percentageCorrect : ' + lookForLetter.index.percentageCorrect);
		Globals.FINGER_SPELLING_DATA = lookForLetter;

		if (this._usingKeyboardForDebug) {
			this._sameLetterInARow = false;
		}
		if (this._sameLetterInARow === true) {
			// Check if movement on X is sufficent
			var differenceOnX = this._lastDetectionBaseXPos - result[0][0];
			if (differenceOnX < 0) {
				differenceOnX = differenceOnX * -1;
			}
			//	console.log('differenceOnX : ' + differenceOnX);
			if (differenceOnX > 0.02 * fingerData.handSize) {
				this._hasFoundLetter = false;
				this._sameLetterInARow = false;
			}
		}

		// Animate percenate
		gsap.to(this._percentageCorrect, {
			duration: Globals.GAME_SETTINGS.timeToAcceptHandPose,
			count: countCorrectFingers * 20,
			onUpdate: this.updatePercentageCorrect,
			onUpdateParams: [result],
			ease: 'power1.out'
		});

		var hideFingerDescription = { duration: 0.2, height: '0px', ease: 'power3.in' };
		var showFingerDescription = { duration: 0.2, height: 'auto', ease: 'power3.out' };

		// Update Finger Descriptor card
		if (lookForLetter.thumb.percentageCorrect === 1 && this._foundMatches.thumb === false) {
			console.log('hiding');
			this._foundMatches.thumb = true;
			gsap.to(this._thumb, hideFingerDescription);
		} else if (lookForLetter.thumb.percentageCorrect !== 1 && this._foundMatches.thumb === true) {
			this._foundMatches.thumb = false;
			if (this._allowFingerDescription) {
				gsap.to(this._thumb, showFingerDescription);
			}
		}
		if (lookForLetter.index.percentageCorrect === 1 && this._foundMatches.index === false) {
			this._foundMatches.index = true;
			gsap.to(this._index, hideFingerDescription);
		} else if (lookForLetter.index.percentageCorrect !== 1 && this._foundMatches.index === true) {
			this._foundMatches.index = false;
			if (this._allowFingerDescription) {
				gsap.to(this._index, showFingerDescription);
			}
		}
		if (lookForLetter.middle.percentageCorrect === 1 && this._foundMatches.middle === false) {
			this._foundMatches.middle = true;
			gsap.to(this._middle, hideFingerDescription);
		} else if (lookForLetter.middle.percentageCorrect !== 1 && this._foundMatches.middle === true) {
			this._foundMatches.middle = false;
			if (this._allowFingerDescription) {
				gsap.to(this._middle, showFingerDescription);
			}
		}
		if (lookForLetter.ring.percentageCorrect === 1 && this._foundMatches.ring === false) {
			this._foundMatches.ring = true;
			if (this._allowFingerDescription) {
				gsap.to(this._ring, hideFingerDescription);
			}
		} else if (lookForLetter.ring.percentageCorrect !== 1 && this._foundMatches.ring === true) {
			this._foundMatches.ring = false;
			if (this._allowFingerDescription) {
				gsap.to(this._ring, showFingerDescription);
			}
		}
		if (lookForLetter.little.percentageCorrect === 1 && this._foundMatches.pinky === false) {
			this._foundMatches.pinky = true;
			gsap.to(this._pinky, hideFingerDescription);
		} else if (lookForLetter.little.percentageCorrect !== 1 && this._foundMatches.pinky === true) {
			this._foundMatches.pinky = false;
			if (this._allowFingerDescription) {
				gsap.to(this._pinky, showFingerDescription);
			}
		}

		/*	if (this._sameLetterInARow === false && countCorrectFingers === 5) {
			if (this._hasFoundLetter === false) {
				//this._foundLetterTimeout = setInterval(this.setNewLetter, 1000)
				//	gsap.to(Globals.INNER_BOUNDING_BOX, 1, { scaleX: 1, onComplete: this.letterMatch, onCompleteParams: [result] });
			}
			this._hasFoundLetter = true;
		} else {
			this._hasFoundLetter = false;
			gsap.killTweensOf(Globals.INNER_BOUNDING_BOX);
			gsap.set(Globals.INNER_BOUNDING_BOX, {scaleX: 0, transformOrigin: '0px 0px'});
		}*/
	};

	private updatePercentageCorrect = result => {
		var value = Math.floor(this._percentageCorrect.count);
		this._getPercentageCorrectElement.innerHTML = value + '%';
		if (value === 100 && this._hasFoundLetter === false && this._sameLetterInARow === false) {
			gsap.killTweensOf(this._percentageCorrect);
			this._hasFoundLetter = true;
			this._checkForNewLetter = false;
			//gsap.delayedCall(Globals.GAME_SETTINGS.timeToAcceptHandPose / 2, this.letterMatch, [result])
			console.log('update percentage');

			if (this._letterRequiresMotion) {
				this._motionArray = [];
				this._lookForMotion = true;
			} else {
				this.letterMatch(result);
			}
		}
	};

	private letterMatch = result => {
		console.log('letterMatch ');

		if (this._skipLetterClicked === false) {
			gsap.to(this._percentageMatchElement, { delay: 0.1, duration: 0.1, x: 0, opacity: 1, ease: 'power1.out' });
			var newX = -200;
			if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
				newX = 200;
			}

			gsap.to(this._getPercentageCorrectElement, { duration: 0.2, x: 0, opacity: 0, ease: 'power1.out' });
		}
		this._gameObject.letterCount = this._gameObject.letterCount + 1;
		this.updateScore();

		this._checkForNewLetter = false;
		// Store base pos
		if (Globals.DEBUG_TURN_OFF_HANDPOSE !== true && result) {
			this._lastDetectionBaseXPos = result[0][0];
		}

		// Activate new letter
		gsap.delayedCall(0.4, this.setNewLetter);
	};

	private allowfingerDescriptions = () => {
		this._allowFingerDescription = true;
	};

	public onRequestAnimationFrame = () => {};

	public in = () => {
		document.body.style.backgroundColor = '#fff6df';

		Globals.MAIN.hideTopBar();
		//	gsap.to(getHand.position, { delay: 0.2, duration: 1, x: -0.35, z: 2, ease: 'power1.inOut' });
		Globals.HAND_SCENE.changeHandColor(0x3109cd);
		Globals.HAND_SCENE.animateBackgroundColor(0xfff6df);
		gsap.delayedCall(0.4, Globals.HAND_SCENE.animateToHalfSize);

		gsap.to(this._backButton, { duration: 0.4, delay: 0.3, x: 0, ease: 'power2.out' });

		//Globals.HAND_SCENE.playHandAnimation(39); // 39
		// Wave animation

		Globals.VIEW_MANAGER.addView(this.element);
		Globals.ACTIVE_VIEW = this;

		gsap.delayedCall(2, this.startShowingLetters);
		gsap.delayedCall(0.8, this.handMovedToSide);
	};

	private handMovedToSide = () => {
		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { delay: 0.2, duration: 1, x: 0, z: 2, ease: 'power3.inOut' });
		gsap.to(getHand.rotation, { duration: 0.5, z: 0.3, ease: 'power2.inOut', yoyo: true, repeat: -1 });

		this._rightSide.style.display = 'block';
	};

	private startShowingLetters = () => {
		gsap.to(this._rightSideBG, { duration: 1, width: '0%', ease: 'power1.in' });

		gsap.to(this._helloThere, { duration: 1, opacity: 1, y: 0, ease: 'power2.out' });
		this.animatedIn();
		Globals.HAND_POSE_DETECTION.allowHandAnalysis();
		if (Globals.DEBUG_TURN_OFF_HANDPOSE) {
			this.foundRaisedHand();
		}
	};

	private animatedIn = () => {
		Globals.PAUSE_HAND_DETECTION = false;
		this.inComplete();
	};

	public out = () => {
		Globals.IN_GAME = false;

		var getHand = Globals.HAND_SCENE.getHand();

		gsap.killTweensOf([getHand.rotation, getHand.position]);
		gsap.killTweensOf([this._progressBar, this._nextLetterOverlay]);
		clearInterval(this._timerInterval);
		Globals.PAUSE_HAND_DETECTION = true;
		if (Globals.DEBUG_TURN_OFF_HANDPOSE) {
			document.body.removeEventListener('click', this.letterMatch);
		}
		gsap.to(this._rightSideBG, { duration: 0.2, width: '100%', ease: 'power1.in', onComplete: this.rightSideHidden });

		gsap.to(this._letterToFindBig, { duration: 0.2, opacity: 0 });
		gsap.to(this._nextLetterOverlay, { duration: 0.2, opacity: 0 });

		this._backButton.removeEventListener('mouseover', this.mouseOverBack);
		this._backButton.removeEventListener('mouseout', this.mouseOutBack);
		this._backButton.removeEventListener('click', this.clickBack);
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: -100, ease: 'power2.out' });

		gsap.to(this._wordSpanArray, { duration: 0.2, ease: 'power2.in', y: 150, stagger: 0.02 });
		gsap.to(this._skipLetterButton, { duration: 0.1, ease: 'power2.in', y: 150 });

		var newX = -200;
		if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
			newX = 200;
		}

		gsap.to(this._percentageMatchElement, { duration: 0.1, delay: 0.2, x: 0, opacity: 0, ease: 'power1.in' });
		this._checkForNewLetter = false;
	};

	private rightSideHidden = () => {
		Globals.HAND_SCENE.animateToNormalSize();
		var getHand = Globals.HAND_SCENE.getHand();
		this._rightSide.style.display = 'none';
		gsap.to(getHand.position, { duration: 0.6, x: 0, y: 0, z: 0, ease: 'power1.inOut', onComplete: this.outComplete });
		gsap.to(getHand.rotation, { duration: 0.4, z: 0, x: 0, y: 0, ease: 'power1.inOut' });
	};

	kill() {
		super.kill();
	}
}
