import * as THREE from 'three';
import { AnimationAction, MeshBasicMaterial, MeshLambertMaterial, MeshPhongMaterial, MeshToonMaterial, Object3D } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { gsap } from 'gsap';
import * as THREE from '../build/three.module.js';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Globals } from '../data/Globals';

export class HandScene {
	private _camera: THREE.PerspectiveCamera;
	private _scene: THREE.Scene;
	private _renderer: THREE.WebGLRenderer;
	private _clock: THREE.Clock;
	private _mixer: THREE.AnimationMixer;

	private _animateResize = { width: 0, height: 0 };

	private _animationMixes: Array<THREE.AnimationAction> = [];
	private _currentAnimation: AnimationAction;

	private _handLoadedCallback: Function;

	private _currentWordInSentenceCount: number = 0;
	private _sentenceArray = [];

	private _hand;

	private _DEBUG = false;

	private _halfSize: boolean = false;

	private _movedToLeft: boolean = false;

	private _handMaterial: THREE.MeshStandardMaterial;
	private _previousLetter: number;

	private _isRightHand: boolean = true;

	private _container: HTMLDivElement;

	private _currentBGColor = { color: 0x683aff };

	private _isSpelling: boolean = false;

	get DEBUG(): boolean {
		return this._DEBUG;
	}

	set DEBUG(value: boolean) {
		this._DEBUG = value;
	}

	constructor(container: HTMLDivElement, onHandLoaded: Function) {
		this._clock = new THREE.Clock();
		this.init(container);
		this.animate();
	}

	private init = (container: HTMLDivElement) => {
		this._camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 200000);
		this._camera.position.set(0, 0, 250);

		this._scene = new THREE.Scene();
		this._scene.background = new THREE.Color(this._currentBGColor.color);

		this._scene.fog = new THREE.Fog(this._currentBGColor.color, 200, 1000);

		const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
		hemiLight.position.set(0, 200, 0);
		this._scene.add(hemiLight);

		/*	const hemiLight2 = new THREE.HemisphereLight(0xffffff, 0x444444);
		hemiLight2.position.set(0, -1000, 0);
		this._scene.add(hemiLight2);*/

		const dirLight = new THREE.DirectionalLight(0xffffff, 0.8);
		dirLight.position.set(0, 150, 200);
		//	dirLight.castShadow = true;
		/*	dirLight.shadow.camera.top = 180;
		dirLight.shadow.camera.bottom = -100;
		dirLight.shadow.camera.left = -120;
		dirLight.shadow.camera.right = 120;*/
		this._scene.add(dirLight);

		const dirLight2 = new THREE.SpotLight(0xffffff);
		dirLight2.position.set(100, -100, 100);
		//	dirLight.castShadow = true;
		this._scene.add(dirLight2);

		const spotLightHelper = new THREE.SpotLightHelper(dirLight2);
		//this._scene.add( spotLightHelper );

		// scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );

		// ground
		const mesh = new THREE.Mesh(new THREE.PlaneGeometry(2000, 2000), new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }));
		mesh.rotation.x = -Math.PI / 2;
		mesh.receiveShadow = true;
		//this._scene.add( mesh );

		/*	const grid = new THREE.GridHelper( 2000, 20, 0x000000, 0x000000 );
		grid.material.opacity = 0.2;
		grid.material.transparent = true;
		scene.add( grid );*/

		this._renderer = new THREE.WebGLRenderer({ antialias: true });
		this._renderer.setPixelRatio(window.devicePixelRatio);
		this._renderer.setSize(window.innerWidth / 2, window.innerHeight);
		this._renderer.shadowMap.enabled = true;
		//	this._renderer.domElement.style.zIndex = '100';
		//	this._renderer.domElement.style.position = 'absolute';
		container.appendChild(this._renderer.domElement);
		this._container = container;
		/*const controls = new OrbitControls(this._camera, this._renderer.domElement);
		controls.target.set(0, 0, 0);
		controls.update();*/

		window.addEventListener('resize', this.onWindowResize);

		this.onWindowResize();

		// stats
		//	stats = new Stats();
		//	container.appendChild( stats.dom );
	};

	public animateBackgroundColor = newColor => {
		var setColor = new THREE.Color(newColor);
		gsap.to(this._scene.background, { duration: 0.4, r: setColor.r, g: setColor.g, b: setColor.b, ease: 'power1.out' });
	};

	public loadHand = (loadHandCallback: Function, onProgressCallback: Function) => {
		this._handLoadedCallback = loadHandCallback;
		// model
		const loader = new GLTFLoader();

		// Load a glTF resource
		loader.load(
			// resource URL
			'assets/3d/rigget_V16.glb',
			// called when the resource is loaded
			this.onHandLoaded,
			// called while loading is progressing
			function(xhr) {
				var percentLoaded = (xhr.loaded / xhr.total) * 100;
				console.log(percentLoaded + '% loaded');
				onProgressCallback(percentLoaded);
			},
			// called when loading has errors
			function(error) {
				console.log(error);
			}
		);
	};

	private onHandLoaded = gltf => {
		console.log(gltf);
		var child = gltf.scene.children[0];

		const dirLight3 = new THREE.SpotLight(0xffffff);
		dirLight3.position.set(-100, -100, 100);
		//	dirLight.castShadow = true;
		dirLight3.lookAt(child);
		this._scene.add(dirLight3);

		//child.receiveShadow = true;
		//child.castShadow = true;

		child.scale.set(6, 6, 6);
		//	child.position.y = -1;
		//child.rotation.y = (-90 * Math.PI) / 180;
		//child.rotation.z = (-10 * Math.PI) / 180;
		console.log(child);
		console.log(child.children[1]);

		this._handMaterial = child.children[1].children[0].material;
		console.log(this._handMaterial);
		this._handMaterial.color.setHex(0x3109cd);
		this._handMaterial.roughness = 0.45;
		this._handMaterial.metalness = 0.0;

		/*var material = new MeshPhongMaterial({color: '0xff0000'});
		child.material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );

		child.children[0].material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
		child.children[0].children[0].material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
		child.children[2].material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );

		child.needsUpdate = true;
		child.material.needsUpdate = true;*/
		console.log(child);

		this._hand = child;

		// FIXME - This turns it into a right hand (else its the left hand)
		this._isRightHand = true;
		this._hand.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
		child.position.z = -700;
		this._scene.add(gltf.scene);

		this._mixer = new THREE.AnimationMixer(gltf.scene);

		var animations = gltf.animations;

		for (var i: number = 0; i < animations.length; i++) {
			var getAnim = this._mixer.clipAction(animations[i]);
			if (i > 0) {
				getAnim.time = animations[i].duration / 2;

				if (i === 10) {
					getAnim.time = 0;
				} else if (i === 26 || i === 27) {
					getAnim.time = 0;
				}

				getAnim.paused = true;
				getAnim.play();
				getAnim.weight = 0;
			} else {
				this._currentAnimation = getAnim;
			}

			this._animationMixes.push(getAnim);
		}

		gltf.animations; // Array<THREE.AnimationClip>
		gltf.scene; // THREE.Group
		gltf.scenes; // Array<THREE.Group>
		gltf.cameras; // Array<THREE.Camera>
		gltf.asset; // Object

		this._handLoadedCallback();
	};

	public changeHandColor = newColor => {
		var setColor = new THREE.Color(newColor);
		gsap.to(this._handMaterial.color, { duration: 0.5, r: setColor.r, g: setColor.g, b: setColor.b, ease: 'power1.out' });
	};

	public makeLeftHand = () => {
		console.log('makeLeftHand : ' + this._isRightHand);
		if (this._isRightHand === true) {
			this._hand.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
			this._isRightHand = false;
		}
	};

	public makeRightHand = () => {
		console.log('makeRightHand : ' + this._isRightHand);

		if (this._isRightHand === false) {
			this._hand.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
			this._isRightHand = true;
		}
	};

	private onWindowResize = () => {
		var width = window.innerWidth;
		var height = window.innerHeight;
		if (this._halfSize) {
			if (Globals.IS_MOBILE === true) {
				height = window.innerHeight / 2;
			} else {
				width = window.innerWidth / 2;
			}
			if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
				gsap.set(this._container, { duration: 0.4, x: window.innerWidth / 2 });
			}
		}

		/*	this._camera.aspect = width / window.innerHeight;
		this._camera.updateProjectionMatrix();

		*/
		this._renderer.setSize(width, height);
		this._renderer.setViewport(0, 0, width, height);
		this._camera.fov = height / 1600;
		this._camera.aspect = width / height;
		this._camera.updateProjectionMatrix();
	};

	private animateResize = () => {
		var width = this._animateResize.width;
		var height = this._animateResize.height;

		this._camera.aspect = width / height;
		this._camera.updateProjectionMatrix();

		this._renderer.setSize(width, height);
	};

	//

	private animate = () => {
		requestAnimationFrame(this.animate);

		const delta = this._clock.getDelta();

		if (this._mixer) this._mixer.update(delta);

		this._renderer.render(this._scene, this._camera);

		//	stats.update();
	};

	public convertLetterToNumber = (letter: string) => {
		const alphaVal = letter.toLowerCase().charCodeAt(0) - 97 + 1;
		return alphaVal;
	};

	public setNewHand = (count: number, onCompleteCallback) => {
		console.log('*** setNewHand : ' + count);
		console.log(onCompleteCallback);
		if (count === 0) {
			this.stopSpelling();
			return;
		}

		if (count > this._animationMixes.length) {
			count = 0;
			console.log('Letter not year created - count : ' + count);
		}
		//	console.log('count : ' + count);
		var getHandAnimation: AnimationAction = this._animationMixes[count];
		//	console.log(getHandAnimation);

		if (count === 26) {
			// We use the other Z
			getHandAnimation = this._animationMixes[27];
			console.log('---- setting new hand - 27');
		}

		console.log(getHandAnimation);

		var animTime = 0.4 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;
		var delay = 0.3 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;

		if (!onCompleteCallback) {
			delay = 0;
		}

		if (count === 7) {
			// G -- Hand needs to twist more - so we are making this one slower
			animTime = 1 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;
		}
		/*	if (count === 8) {
			// G -- Hand needs to twist more - so we are making this one slower
			animTime = 1 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;
		}*/
		if (getHandAnimation !== this._currentAnimation) {
			if (this._previousLetter === 9 && count === 14) {
				// I to N (fingers collide - trying to fix that)
				//console.log('I to N');
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime, weight: 0, ease: 'power1.easeIn' });
				gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0.3, ease: 'linear.easeNone' });

				gsap.to(this._animationMixes[0], { delay: delay * 1.2, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay * 1.2, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			}
			if (count === 10 || count == 26) {
				// I to N (fingers collide - trying to fix that)
				//console.log('I to N');
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime, weight: 1, ease: 'power1.easeIn' });
				gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				if (count === 26) {
					this.playHandAnimation(count + 1);
				} else {
					this.playHandAnimation(count);
				}
				//gsap.to(this._animationMixes[0], { delay: delay * 1.2, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				//gsap.to(getHandAnimation, { delay: delay * 1.2, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			} else if (this._previousLetter === 7 && count === 5) {
				animTime = 1 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;
				// From G to E
				//console.log('I to N');
				console.log('--- HERE');
				console.log(this._currentAnimation);
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime * 1, time: 0.3, ease: 'linear.easeNone' });
				//gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0.3, ease: 'linear.easeNone' });

				gsap.to(this._currentAnimation, { delay: delay * 1.3, duration: 1, weight: 0, ease: 'linear.easeNone' });
				gsap.to(this._currentAnimation, { delay: delay * 1.3 + 1, duration: 1, time: 3, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay * 1.3 + 0.2, duration: animTime * 1, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			} else if (this._previousLetter === 8 && count === 20) {
				animTime = 1 * Globals.GAME_SETTINGS.handSpellSlowdown * 1;
				// From G to E
				//console.log('I to N');
				console.log('--- HERE');
				console.log(this._currentAnimation);
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime * 1, time: 0.3, ease: 'linear.easeNone' });
				//gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 0.3, ease: 'linear.easeNone' });

				gsap.to(this._currentAnimation, { delay: delay * 1.3 + 0.5, duration: 1, weight: 0, ease: 'linear.easeNone' });
				gsap.to(this._currentAnimation, { delay: delay * 1.3 + 0.5 + 1, duration: 1, time: 3, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay * 1.3 + 1, duration: animTime * 1, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			} else {
				gsap.to(this._animationMixes[0], { delay: delay, duration: animTime, weight: 1, ease: 'linear.easeNone' });
				console.log(this._currentAnimation);
				gsap.to(this._currentAnimation, { delay: delay, duration: animTime, weight: 0, ease: 'linear.easeNone' });
				gsap.to(getHandAnimation, { delay: delay, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback });
			}

			//	gsap.to(this._currentAnimation, {delay: 0.3, duration: animTime, weight: 0, ease: 'linear.easeNone'});
			//	gsap.to(getHandAnimation, {delay: 0.3, duration: animTime, weight: 1, ease: 'linear.easeNone', onComplete: onCompleteCallback});
		} else {
			gsap.to(this._animationMixes[0], { delay: 0.3, duration: animTime, weight: 1, ease: 'linear.easeNone' });

			// Its the same letter - so we move the hand slightly on the X axis
			if (Globals.IN_GAME !== true) {
				if (this._movedToLeft) {
					gsap.to(this._hand.position, { delay: 0.3, duration: animTime, x: 0, onComplete: onCompleteCallback });
					this._movedToLeft = false;
				} else {
					gsap.to(this._hand.position, { delay: 0.3, duration: animTime, x: -0.1, onComplete: onCompleteCallback });
					this._movedToLeft = true;
				}
			} else {
				gsap.delayedCall(0.3 + animTime, onCompleteCallback);
			}
		}

		this._previousLetter = count;
		this._currentAnimation = getHandAnimation;
	};

	public playHandAnimation = number => {
		this.stopSpelling();
		gsap.delayedCall(0.3, this.startAnim, [number]);
	};

	private startAnim = number => {
		var getAnim = this._animationMixes[number];
		getAnim.time = 0;
		getAnim.paused = false;
		getAnim.play();
		getAnim.weight = 1;
	};

	public spellLetter = letter => {
		console.log('**** spellLetter - ' + letter);
		this.stopSpelling();

		this._sentenceArray = [0];
		this._currentWordInSentenceCount = 0;
		this.nextLetter();
	};

	public spellSentence = sentence => {
		console.log('**** spellSentence - ' + sentence);
		this.stopSpelling();
		console.log(sentence);
		sentence = sentence.split('');
		this._sentenceArray = [];
		for (var i = 0; i < sentence.length; i++) {
			var currentLetter = sentence[i];
			var letterToNumber = this.convertLetterToNumber(currentLetter);
			//	console.log('currentLetter : ' + currentLetter + ' , letterToNumber: ' + letterToNumber);
			this._sentenceArray.push(letterToNumber);
		}
		this._currentWordInSentenceCount = 0;
		this.nextLetter();
	};

	public spellPreloaderAnim = () => {
		// Animation; 1, 2, 3, 4, 5
		this._sentenceArray = [27, 28, 29, 30];
		this._currentWordInSentenceCount = 0;
		this.nextLetter();
	};

	private nextLetter = () => {
		var length = this._sentenceArray.length;

		console.log('this._sentenceArray[this._currentWordInSentenceCount] : ' + this._sentenceArray[this._currentWordInSentenceCount]);
		console.log(this._currentWordInSentenceCount);

		if (length === 1) {
			this.setNewHand(this._sentenceArray[this._currentWordInSentenceCount], null);
		} else {
			this.setNewHand(this._sentenceArray[this._currentWordInSentenceCount], this.nextLetter);
		}
		this._currentWordInSentenceCount++;

		if (this._currentWordInSentenceCount >= this._sentenceArray.length) {
			this._currentWordInSentenceCount = 0;
		}
	};

	public stopSpelling = () => {
		console.log('***** stopspelling');
		this._currentWordInSentenceCount = 0;
		this._sentenceArray = [];

		gsap.killTweensOf(this._currentAnimation);
		gsap.killTweensOf(this._animationMixes[0]);
		gsap.killTweensOf(this._animationMixes);
		//gsap.killTweensOf(this._hand.position);
		//gsap.killTweensOf(this._hand.position);
		if (Globals.IN_GAME !== true) {
			gsap.to(this._hand.position, { delay: 0.0, duration: 0.3, x: 0, onComplete: null });
		}
		//	gsap.killTweensOf(this._animationMixes);
		gsap.to(this._animationMixes, { duration: 0.3, weight: 0 });

		this._currentAnimation = null;
		this._movedToLeft = false;

		/*	var getHandAnimation: AnimationAction = this._animationMixes[0];
		gsap.to(this._currentAnimation, { delay: 0, duration: 0.2, weight: 0, ease: 'power2.out' });
		gsap.to(getHandAnimation, { duration: 0.2, weight: 1, ease: 'power2.out' });*/
	};

	public getPositionIn3D = (posX, posY) => {
		//https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z
		var vec = new THREE.Vector3(); // create once and reuse
		var pos = new THREE.Vector3(); // create once and reuse

		vec.set((posX / window.innerWidth) * 2 - 1, -(posY / window.innerHeight) * 2 + 1, 0.5);

		vec.unproject(this._camera);

		vec.sub(this._camera.position).normalize();

		var distance = -this._camera.position.z / vec.z;

		pos.copy(this._camera.position).add(vec.multiplyScalar(distance));

		return pos;
	};

	public getHand = () => {
		return this._hand;
	};

	public animateToHalfSize = () => {
		this._halfSize = true;
		this._animateResize.height = window.innerHeight;
		this._animateResize.width = window.innerWidth;
		if (Globals.IS_MOBILE === true) {
			gsap.to(this._animateResize, { duration: 0.4, height: window.innerHeight / 2, ease: 'power1.inOut', onUpdate: this.animateResize });
		} else {
			if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
				gsap.to(this._container, { duration: 0.4, x: window.innerWidth / 2, ease: 'power1.inOut' });
			}
			gsap.to(this._animateResize, { duration: 0.4, width: window.innerWidth / 2, ease: 'power1.inOut', onUpdate: this.animateResize });
		}
	};

	public animateToNormalSize = () => {
		this._halfSize = false;
		this._animateResize.width = window.innerWidth / 2;
		gsap.to(this._animateResize, { duration: 0.4, width: window.innerWidth, height: window.innerHeight, ease: 'power1.inOut', onUpdate: this.animateResize });
		if (Globals.LEFT_HAND_SELECTED && Globals.IS_MOBILE === false) {
			gsap.to(this._container, { duration: 0.4, x: 0, ease: 'power1.inOut' });
		}
	};
}
