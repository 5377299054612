import { Back, Linear, Power1, Power2, gsap } from 'gsap';
import { Globals } from '../data/Globals';

export class SetupVideo {
	private _userFeedVideo: HTMLVideoElement;
	private _videoContainer: HTMLDivElement;

	private _successCallback: Function;

	constructor() {
		this.setupVideo();
	}

	private setupVideo = () => {
		Globals.VIDEO_SETUP = true;
		this._userFeedVideo = document.querySelector('#userFeedVideo') as HTMLVideoElement;
		this._videoContainer = document.querySelector('#videoContainer') as HTMLDivElement;
		Globals.USER_VIDEO_CONTAINER = this._videoContainer;
	};

	public requestCameraAccess = (successCallback: Function) => {
		this._successCallback = successCallback;
		var video = this._userFeedVideo;
		this._userFeedVideo.addEventListener('loadeddata', this.videoStarted);

		let options = { audio: false, video: true, facingMode: 'user', width: { ideal: 1280 }, height: { ideal: 720 } };

		if (Globals.IS_MOBILE === true) {
			options = { audio: false, video: true, facingMode: 'user', width: { ideal: 1920 }, height: { ideal: 1080 } };
		}

		if (navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({
					audio: false,
					video: options
				})
				.then(function(stream) {
					console.log(stream);
					/*	alert(stream.getVideoTracks()[0].getSettings().width)
					alert(stream.getVideoTracks()[0].getSettings().height)*/
					//	video.width = stream.getVideoTracks()[0].getSettings().width;
					//	video.height = stream.getVideoTracks()[0].getSettings().height;
					video.srcObject = stream;
					video.onloadedmetadata = function() {
						console.log('width is', this.videoWidth);
						console.log('height is', this.videoHeight);
						video.width = this.videoWidth;
						video.height = this.videoHeight;
						Globals.VIDEO_WIDTH = this.videoWidth;
						Globals.VIDEO_HEIGHT = this.videoHeight;
					};

					let promise = video.play();

					if (promise !== undefined) {
						promise
							.then(() => {})
							.catch(error => {
								console.log(error);
								//	alert('Error: Camera not allowed');
							});
					}
				})
				.catch(function(error) {
					console.log(error);
					alert(
						"Unfortunately we could not access your camera (either you clicked disallow or the camera wasn't found) - check your camera settings by clicking the camera icon in the browserbar. And reload the page."
					);
					//alert('Error: Camera not allowed');
				});
		}
	};

	private videoStarted = event => {
		this.cameraAccessGiven();
	};

	private cameraAccessGiven = () => {
		Globals.HAS_ALLOWED_WEBCAM = true;
		/*this._userFeedVideo.width = window.innerWidth;//this.videoWidth;
		this._userFeedVideo.height = window.innerHeight;//this.videoHeight;

		this._userFeedVideo.style.width = window.innerWidth + 'px';
		this._userFeedVideo.style.height = window.innerHeight + 'px';*/
		this._videoContainer.style.display = 'none';
		//	this._videoContainer.style.zIndex = 1000;
		//	this._videoContainer.style.visibility = 'visible';
		this._successCallback();
		//	this.startDetection();
	};

	public getVideoFeed = () => {
		return this._userFeedVideo;
	};
}
