import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class LevelSelectView extends AbstractView {
	private _animatableElements: Array<HTMLDivElement> = [];
	private _aboutElement: HTMLDivElement;
	private _backButton: HTMLDivElement;

	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this.element = element;

		var getLevels = element.querySelectorAll('.level');
		for (var i = 0; i < getLevels.length; i++) {
			var currentElement: HTMLDivElement = getLevels[i] as HTMLDivElement;
			//	currentElement._splitText =
			currentElement._count = i;
			currentElement.addEventListener('mouseover', this.mouseOverLevelSelect);
			currentElement.addEventListener('mouseout', this.mouseOutLevelSelect);
			currentElement.addEventListener('click', this.clickLevel);
			gsap.set(currentElement, { x: window.innerWidth * -1 });
			this._animatableElements.push(currentElement);
		}

		this._aboutElement = element.querySelector('.about');
		gsap.set(this._aboutElement, { y: 200 });
		this._aboutElement.addEventListener('mouseover', this.mouseOverAbout);
		this._aboutElement.addEventListener('mouseout', this.mouseOutAbout);
		this._aboutElement.addEventListener('click', this.clickAbout);

		this._backButton = element.querySelector('.backArrow');
		this._backButton.addEventListener('mouseover', this.mouseOverBack);
		this._backButton.addEventListener('mouseout', this.mouseOutBack);
		this._backButton.addEventListener('click', this.clickBack);

		console.log('LevelSelectView - init()');
	}

	private mouseOverBack = event => {
		var getTarget = event.currentTarget;
		//.children[0].children[0].children[0]
		gsap.to(getTarget, { duration: 0.2, scale: 1.2, ease: 'power1.inOut' });
	};

	private mouseOutBack = event => {
		var getTarget = event.currentTarget;
		gsap.to(getTarget, { duration: 0.2, scale: 1, ease: 'power1.inOut' });
	};

	private clickBack = () => {
		Globals.VIEW_MANAGER.setPath('');
	};

	private mouseOverLevelSelect = event => {
		var random = Math.floor(Math.random() * 6);

		var getHand = Globals.HAND_SCENE.getHand();

		var target = event.currentTarget;
		Globals.HAND_SCENE.setNewHand(target._count + 28);

		var getTargetPos = target.getBoundingClientRect();

		var moveTo = Globals.HAND_SCENE.getPositionIn3D(getTargetPos.x, getTargetPos.y + 50);

		gsap.to(getHand.position, { duration: 0.2, y: moveTo.y, ease: 'power1.inOut' });
		//	gsap.to(getHand.rotation, {duration: 0.2, x: 3.14159265 / 1.6, ease: 'power1.inOut'});

		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');
		//	gsap.to(target, { duration: 0.2, backgroundColor: '#ffffff', ease: 'power1.in' });
		gsap.to(getInnerBG, { duration: 0.2, x: -1, ease: 'power1.in' });
		//	console.log(this._buttonSplitText);
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#683AFF' });

		//	gsap.to(this._buttonSplitText.chars, { duration: 0.3, skewX: '-10deg', x: 0, force3D: true, ease: 'expo.out', stagger: 0.04 });
	};

	private mouseOutLevelSelect = event => {
		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');

		var getHand = Globals.HAND_SCENE.getHand();

		//	gsap.to(getHand.position, {duration: 0.2, y: 0, ease: 'power1.easeOut'});

		//gsap.to(getHand.rotation, {duration: 0.2, x: 0, ease: 'power1.easeOut'});

		Globals.HAND_SCENE.setNewHand(0);

		//gsap.to(target, { duration: 0.1, backgroundColor: '#ffe090', ease: 'power1.in' });
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#FFF6DF' });

		gsap.to(getInnerBG, { duration: 0.2, x: -360, ease: 'power1.out' });
	};

	private clickLevel = event => {
		var target = event.currentTarget;
		Globals.LEVEL = target._count + 1;

		Globals.VIEW_MANAGER.setPath('startLevel');
	};

	/**
	 * ABOUT
	 */
	private mouseOverAbout = event => {
		var getHand = Globals.HAND_SCENE.getHand();

		var target = event.currentTarget;
		Globals.HAND_SCENE.setNewHand(1);

		var getTargetPos = target.getBoundingClientRect();

		var moveTo = Globals.HAND_SCENE.getPositionIn3D(getTargetPos.x, getTargetPos.y - 80);

		gsap.to(getHand.position, { duration: 0.2, y: moveTo.y, ease: 'power1.inOut' });
		//	gsap.to(getHand.rotation, {duration: 0.2, x: 3.14159265 / 1.6, ease: 'power1.inOut'});

		gsap.to(target, { duration: 0.2, color: '#fff6df' });
	};

	private mouseOutAbout = event => {
		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');

		var getHand = Globals.HAND_SCENE.getHand();

		//	gsap.to(getHand.position, {duration: 0.2, y: 0, ease: 'power1.easeOut'});

		//gsap.to(getHand.rotation, {duration: 0.2, x: 0, ease: 'power1.easeOut'});

		Globals.HAND_SCENE.setNewHand(0);

		gsap.to(target, { duration: 0.2, color: '#ffe090' });
	};

	private clickAbout = event => {
		Globals.VIEW_MANAGER.setPath('about');
	};

	public onRequestAnimationFrame = () => {};

	public in = () => {
		Globals.HAND_SCENE.stopSpelling();
		Globals.HAND_SCENE.animateBackgroundColor(0x683aff);

		Globals.MAIN.showTopBar('#ffe090');
		Globals.HAND_SCENE.changeHandColor(0x3109cd);

		gsap.to(this._animatableElements, { duration: 0.4, x: 0, stagger: 0.1, ease: 'power2.out', onComplete: this.animatedIn });
		gsap.to(this._aboutElement, { duration: 0.4, y: 0, ease: 'power2.out' });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: 0, ease: 'power2.out' });

		Globals.VIEW_MANAGER.addView(this.element);
		Globals.ACTIVE_VIEW = this;
	};

	private animatedIn = () => {
		this.inComplete();
	};

	public out = () => {
		for (var i = 0; i < this._animatableElements.length; i++) {
			var getElement = this._animatableElements[i];
			getElement.removeEventListener('mouseover', this.mouseOverLevelSelect);
			getElement.removeEventListener('mouseout', this.mouseOutLevelSelect);
			getElement.removeEventListener('click', this.clickLevel);
		}

		this._aboutElement.removeEventListener('mouseover', this.mouseOverAbout);
		this._aboutElement.removeEventListener('mouseout', this.mouseOutAbout);
		this._aboutElement.removeEventListener('click', this.clickAbout);

		this._backButton.removeEventListener('mouseover', this.mouseOverBack);
		this._backButton.removeEventListener('mouseout', this.mouseOutBack);
		this._backButton.removeEventListener('click', this.clickBack);

		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { duration: 0.4, y: 0, ease: 'power1.inOut' });
		gsap.to(this._aboutElement, { duration: 0.4, y: 300, ease: 'power2.in' });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: -100, ease: 'power2.out' });

		gsap.to(this._animatableElements, { duration: 0.4, x: window.innerWidth, stagger: 0.1, ease: 'power2.in', onComplete: this.outComplete });
	};

	kill() {
		super.kill();
	}
}
