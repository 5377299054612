import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class ChooseHand extends AbstractView {
	private _animatableElements = [];
	private _headline: HTMLDivElement;
	private _subHeadline: HTMLDivElement;

	private _circleTop: HTMLDivElement;
	private _circleBottom: HTMLDivElement;
	private _circles: HTMLDivElement;
	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this.element = element;

		this._circles = element.querySelector('.circles');
		this._circleBottom = element.querySelector('.circleBottom');
		this._circleTop = element.querySelector('.circleTop');
		gsap.set(this._circles, { rotation: 45, scale: 0.2 });
		gsap.set(this._circleTop, { x: -500, scale: 0.2 });
		gsap.set(this._circleBottom, { x: 500, scale: 0.2 });

		this._headline = element.querySelector('.headline');
		this._subHeadline = element.querySelector('.subHeader');
		gsap.set(this._headline, { y: window.innerHeight * -1 });
		gsap.set(this._subHeadline, { y: window.innerHeight * -1 });

		var getLeftHand: HTMLDivElement = element.querySelector('.leftHand');
		getLeftHand._handType = 'left';
		getLeftHand.addEventListener('mouseover', this.mouseOverHandSelect);
		getLeftHand.addEventListener('mouseout', this.mouseOutHandSelect);
		getLeftHand.addEventListener('click', this.clickHand);
		gsap.set(getLeftHand, { x: window.innerWidth * -1 });
		this._animatableElements.push(getLeftHand);

		var getRightHand: HTMLDivElement = element.querySelector('.rightHand');
		getRightHand._handType = 'right';
		getRightHand.addEventListener('mouseover', this.mouseOverHandSelect);
		getRightHand.addEventListener('mouseout', this.mouseOutHandSelect);
		getRightHand.addEventListener('click', this.clickHand);
		gsap.set(getRightHand, { x: window.innerWidth * -1 });
		this._animatableElements.push(getRightHand);
	}

	private mouseOverHandSelect = event => {
		var getHand = Globals.HAND_SCENE.getHand();

		var target = event.currentTarget;

		if (target._handType === 'left') {
			Globals.HAND_SCENE.makeLeftHand();
			//	Globals.HAND_SCENE.setNewHand(12);
			Globals.HAND_SCENE.spellSentence('left');
		} else {
			Globals.HAND_SCENE.makeRightHand();
			//	Globals.HAND_SCENE.setNewHand(18);
			Globals.HAND_SCENE.spellSentence('right');
		}

		var getTargetPos = target.getBoundingClientRect();

		var moveTo = Globals.HAND_SCENE.getPositionIn3D(getTargetPos.x, getTargetPos.y + 100);

		//	gsap.to(getHand.position, { duration: 0.2, y: moveTo.y, ease: 'power1.inOut' });
		//	gsap.to(getHand.rotation, {duration: 0.2, x: 3.14159265 / 1.6, ease: 'power1.inOut'});

		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');
		gsap.to(getInnerBG, { duration: 0.2, x: -4, ease: 'power1.in' });
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#683AFF' });
	};

	private mouseOutHandSelect = event => {
		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');

		var getHand = Globals.HAND_SCENE.getHand();

		//	gsap.to(getHand.position, {duration: 0.2, y: 0, ease: 'power1.easeOut'});

		//gsap.to(getHand.rotation, {duration: 0.2, x: 0, ease: 'power1.easeOut'});
		console.log('--------');
		Globals.HAND_SCENE.spellLetter(0);

		//gsap.to(target, { duration: 0.1, backgroundColor: '#ffe090', ease: 'power1.in' });
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#FFF6DF' });

		gsap.to(getInnerBG, { duration: 0.2, x: -360, ease: 'power1.out' });
	};

	private clickHand = event => {
		var target = event.currentTarget;
		if (target._handType === 'left') {
			Globals.LEFT_HAND_SELECTED = true;
		}

		Globals.VIEW_MANAGER.setPath('chooseLevel');
	};

	public onRequestAnimationFrame = () => {};

	public in = () => {
		Globals.HAND_SCENE.stopSpelling();
		Globals.MAIN.showTopBar('#683AFF', true);
		Globals.HAND_SCENE.changeHandColor(0xa89262);
		Globals.HAND_SCENE.animateBackgroundColor(0xffe090);

		gsap.to(this._circles, { duration: 1.4, rotation: 0, scale: 1, ease: 'power1.out' });
		gsap.to(this._circleTop, { duration: 1.4, delay: 0, x: -0, scale: 1, ease: 'power1.out' });
		gsap.to(this._circleBottom, { duration: 1.4, delay: 0, x: 0, scale: 1, ease: 'power1.out' });

		gsap.to(this._headline, { duration: 0.4, delay: 0.5, y: 0, ease: 'power2.out' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.5, y: 0, ease: 'power2.out' });

		gsap.to(this._animatableElements.reverse(), { delay: 0.5, duration: 0.4, x: 0, stagger: 0.1, ease: 'power2.out', onComplete: this.animatedIn });

		Globals.VIEW_MANAGER.addView(this.element);
		Globals.ACTIVE_VIEW = this;
	};

	private animatedIn = () => {
		this.inComplete();
	};

	public out = () => {
		for (var i = 0; i < this._animatableElements.length; i++) {
			var getElement = this._animatableElements[i];
			getElement.removeEventListener('mouseover', this.mouseOverHandSelect);
			getElement.removeEventListener('mouseout', this.mouseOutHandSelect);
			getElement.removeEventListener('click', this.clickHand);
		}

		gsap.to(this._circles, { duration: 0.4, rotation: -45, scale: 0.2, ease: 'power1.in' });
		gsap.to(this._circleTop, { duration: 0.4, delay: 0, x: -0, scale: 0.2, ease: 'power1.in' });
		gsap.to(this._circleBottom, { duration: 0.4, delay: 0, x: 0, scale: 0.2, ease: 'power1.i' });

		gsap.to(this._headline, { duration: 0.4, delay: 0.0, y: window.innerHeight * -1, ease: 'power2.in' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.1, y: window.innerHeight * -1, ease: 'power2.in', onComplete: this.outComplete });

		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { duration: 0.4, y: 0, x: 0, ease: 'power1.inOut' });
		gsap.to(this._animatableElements, { duration: 0.4, x: window.innerWidth, stagger: 0.1, ease: 'power2.in' });
	};

	kill() {
		super.kill();
	}
}
