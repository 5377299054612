import { Globals } from './data/Globals';
import Stats from 'stats-js';
import { gsap } from 'gsap';
import { HandAnalyzer } from './utils/HandAnalyzer';
import { HandStateManager } from './utils/HandStateManager';
import { Preloader } from './preloader/Preloader';
import { Alphabet } from './utils/Alphabet';

import { ActiveStateController } from './utils/ActiveStateController';

class Main {
	private statsHandTracking: Stats;
	private statsMainThread: Stats;

	private _topBar: HTMLDivElement;

	private _canvas;
	private _canvasContext;

	constructor() {
		gsap.defaults({ overwrite: 'auto' });

		if (window.location.host === 'fingerspelling.xyz' || window.location.host === 'www.fingerspelling.xyz') {
			// FIXME - remove before going live
			Globals.ALLOW_TO_TEST_WITH_KEYBOARD = false;
			//return;
		}

		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
				navigator.userAgent
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				navigator.userAgent.substr(0, 4)
			)
		) {
			Globals.IS_MOBILE = true;
		}

		function isSafari() {
			var ua = navigator.userAgent.toLowerCase();
			if (ua.indexOf('safari') != -1) {
				if (ua.indexOf('chrome') > -1) {
					return false;
				} else {
					return true;
				}
			}
			return false;
		}

		if (Globals.IS_MOBILE === true) {
			Globals.SKIP_TO_MAIN_SCREEN = false;
			Globals.DEBUG_AUTO_SKIP_INTRO_SCREEN = false;
			//	alert('This experience is not available on mobile devices.');
			//	return;
		} else if (isSafari() == true) {
			Globals.SKIP_TO_MAIN_SCREEN = false;
			Globals.DEBUG_AUTO_SKIP_INTRO_SCREEN = false;
			alert('Unfortunately this experience is not available in Safari - try Firefox, Chrome or Edge instead');
			Globals.IS_SAFARI = true;
			//	return;
		}

		if (window.location.host === 'fingerspelling.xyz' || window.location.host === 'finger-spelling.firebaseapp.com') {
			// Turn off console.log's for the live site
			console.log = function() {};
			Globals.DEBUG_AUTO_SKIP_INTRO_SCREEN = false;
			Globals.SKIP_TO_MAIN_SCREEN = false;
			Globals.DEBUG_TURN_OFF_HANDPOSE = false;
			Globals.LEFT_HAND_SELECTED = false;
			Globals.DEBUG_SHOW_FPS_COUNTER = false;
			// Disable deeplinking
			history.pushState({}, null, '/');
		}

		this._topBar = document.body.querySelector('.topBar');

		Globals.HAND_ANALYZER = new HandAnalyzer();
		Globals.MAIN = this;

		this.statsHandTracking = new Stats();
		if (Globals.DEBUG_SHOW_FPS_COUNTER === true) {
			document.body.appendChild(this.statsHandTracking.dom);
			this.statsHandTracking.dom.zIndex = 1000;
		}

		this.statsMainThread = new Stats();
		if (Globals.DEBUG_SHOW_FPS_COUNTER === true) {
			document.body.appendChild(this.statsMainThread.dom);
			this.statsMainThread.dom.zIndex = 1000;
			this.statsMainThread.dom.style.top = '40px';
		}

		Globals.ALPHABET = new Alphabet();

		this.init();
	}

	private init = () => {
		//if (window.location.pathname === '/home') {
		//	window.location.pathname = '/';

		//}

		Globals.PRELOADER = new Preloader(document.querySelector('#Preloader'));

		this.resize();
	};

	public startGlobalRAF = () => {
		// Throttling the FPS (to save some resources)
		//gsap.ticker.fps(30);
		gsap.ticker.add(this.doOnRequestAnimationFrame);
	};

	private doOnRequestAnimationFrame = () => {
		this.statsMainThread.begin();
		//Globals.SETUP_VIDEO.drawVideoToCanvas();

		this.statsMainThread.end();
	};

	public resize = () => {
		if (Globals.VIEW_MANAGER) {
			Globals.VIEW_MANAGER.resize();
		}
	};

	public showTopBar = (newColor, instant) => {
		gsap.to(this._topBar, { duration: 0.3, y: 0, ease: 'power1.out' });

		if (newColor) {
			if (instant) {
				gsap.set(this._topBar.children[0].children[0].children, { fill: newColor });
			} else {
				gsap.to(this._topBar.children[0].children[0].children, { fill: newColor, duration: 0.3 });
			}
		}
	};

	public hideTopBar = () => {
		gsap.to(this._topBar, { duration: 0.3, y: -100, ease: 'power1.in' });
	};
}

window.onload = () => {
	const main = new Main();
	(window as any).Main = main;
};
