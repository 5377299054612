import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';

export class IntroView extends AbstractView {
	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this.element = element;
	}

	public onRequestAnimationFrame = () => {};

	public in = () => {
		Globals.VIEW_MANAGER.addView(this.element);
		gsap.to(this.element, 0.5, { y: 0, ease: 'power1.out', onComplete: this.animatedIn });
		Globals.ACTIVE_VIEW = this;
	};

	private animatedIn = () => {
		this.inComplete();
	};

	public out = () => {
		gsap.to(this.element, 0.5, { y: window.innerHeight, ease: 'power1.in', onComplete: this.outComplete });
	};

	kill() {
		super.kill();
	}
}
