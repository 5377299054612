import { gsap } from 'gsap';
import { Globals } from '../data/Globals';
import { SetupVideo } from './SetupVideo';
import { HandPoseDetection } from './HandPoseDetection';
import { ViewManager } from '../managers/ViewManager';
import { GameView } from '../views/GameView/GameView';
import { HandScene } from '../3d/HandScene';

import SplitText from '../../lib/com/greensock/gsap-bonus/SplitText';
import { LevelSelectView } from '../views/LevelSelectView/LevelSelectView';
import { IntroView } from '../views/IntroView/IntroView';
import { LoadingView } from '../views/LoadingView/LoadingView';
import { LevelStartScreen } from '../views/LevelStartScreen/LevelStartScreen';
import { ChooseHand } from '../views/ChooseHand/ChooseHand';
import { LevelCompletedView } from '../views/LevelCompletedView/LevelCompletedView';
import { AboutView } from '../views/AboutView/AboutView';

gsap.registerPlugin(SplitText);

export class Preloader {
	private _homeContainer: HTMLDivElement;
	private _startButton: HTMLDivElement;

	private _logo: HTMLDivElement;

	private _homeBottomText: HTMLDivElement;

	private _line1: HTMLDivElement;
	private _line2: HTMLDivElement;
	private _line3: HTMLDivElement;

	private _preloaderContainer: HTMLDivElement;
	private _preloaderImageContainer: HTMLDivElement;
	private _videoElement: HTMLVideoElement;

	private _progressBar: HTMLDivElement;
	private _setupVideo: SetupVideo;

	// Hints
	private _hintElement: HTMLDivElement;
	private _hintCount: number = 0;
	private _hintTexts = ['Please wait (Loading Hand Tracking)'];

	private _buttonSplitText: SplitText;

	private element;

	constructor(element: HTMLElement) {
		this.element = element;

		this._homeContainer = this.element.querySelector('.home');
		this._startButton = this._homeContainer.querySelector('.allowWebcamButton');

		this._line1 = this._homeContainer.querySelector('.line1');
		this._line2 = this._homeContainer.querySelector('.line2');
		this._line3 = this._homeContainer.querySelector('.line3');

		if (Globals.IS_MOBILE) {
			this._line3.innerHTML = 'This experience is not available on mobile devices.';
			this._startButton.style.pointerEvents = 'none';
			this._startButton.style.display = 'none';

			gsap.set(this.element, { scale: 0.5 });
		} else if (Globals.IS_SAFARI) {
			this._line3.innerHTML = 'This experience does not work in Safari, please try Chrome, Edge or Firefox instead.';
			this._startButton.style.pointerEvents = 'none';
			this._startButton.style.display = 'none';
		}

		var getInnerText = this._startButton.querySelector('.innerText');
		this._buttonSplitText = new SplitText(getInnerText, { type: 'chars' });
		//if (Globals.SHOW_TURN_ON_WEBCAM === true) {
		this._startButton.addEventListener('click', this.enterSiteClicked);
		this._startButton.addEventListener('mouseover', this.mouseOverEnterButton);
		this._startButton.addEventListener('mouseout', this.mouseOutEnterButton);
		//} else {
		//	this._startButton.style.opacity = '0.2';
		//}
		this._homeBottomText = this._homeContainer.querySelector('.bottomText');
		this._logo = this._homeContainer.querySelector('.line1');
		this._progressBar = this._homeContainer.querySelector('.progressBar');

		gsap.set(this._logo, { y: -200, opacity: 0 });
		gsap.set(this._line2, { y: -200, opacity: 0 });
		gsap.set(this._line3, { y: -200, opacity: 0 });
		gsap.set(this._startButton, { y: 200, opacity: 0 });
		gsap.set(this._homeBottomText, { y: 200, opacity: 0 });
		gsap.set(this._homeContainer, { opacity: 1 });

		this.element.style.visibility = 'visible';

		Globals.HAND_SCENE = new HandScene(document.body.querySelector('.ThreeScene'));
		Globals.HAND_SCENE.loadHand(this.init, this.onLoadProgress);
	}

	private onLoadProgress = percentLoaded => {
		gsap.to(this._progressBar, { duration: 0.5, scaleX: percentLoaded / 100, ease: 'power1.out' });
	};

	private mouseOverEnterButton = event => {
		Globals.HAND_SCENE.stopSpelling();
		var getHand = Globals.HAND_SCENE.getHand();

		var getPosLogo = this._line1.getBoundingClientRect();

		var moveTo = Globals.HAND_SCENE.getPositionIn3D(getPosLogo.x, window.innerHeight / 2 - 200);
		console.log('moveTo : ' + moveTo.y);
		gsap.to(getHand.position, { duration: 0.2, y: moveTo.y, ease: 'power1.inOut' });
		gsap.to(getHand.rotation, { duration: 0.2, x: 3.14159265 / 2, ease: 'power1.inOut' });

		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');
		gsap.to(target, { duration: 0.2, backgroundColor: '#ffffff', ease: 'power1.in' });
		gsap.to(getInnerBG, { duration: 0.2, x: -0, ease: 'power1.in' });
		console.log(this._buttonSplitText);
		//gsap.to(this._buttonSplitText.chars, { duration: 0.3, skewX: '-10deg', x: 0, force3D: true, ease: 'expo.out', stagger: 0.04 });
	};

	private mouseOutEnterButton = event => {
		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');

		var getHand = Globals.HAND_SCENE.getHand();

		gsap.to(getHand.position, { duration: 0.2, y: 0, ease: 'power1.easeOut' });

		gsap.to(getHand.rotation, { duration: 0.2, x: 0, ease: 'power1.easeOut' });

		Globals.HAND_SCENE.spellSentence('fingerspelling');

		gsap.to(target, { duration: 0.1, backgroundColor: '#ffe090', ease: 'power1.in' });
		//gsap.to(this._buttonSplitText.chars, { duration: 0.3, skewX: '-1deg', x: 0, force3D: true, ease: 'expo.out', stagger: 0.04 });

		gsap.to(getInnerBG, { duration: 0.2, x: -360, ease: 'power1.out' });
	};

	private init = () => {
		gsap.to(this._progressBar, { duration: 0.2, y: 12, ease: 'power1.in' });

		Globals.HAND_SCENE.spellSentence('fingerspelling');
		var duration = 0.6;

		gsap.to(this._logo, duration, { delay: 0.4, y: 0, opacity: 1, ease: 'power2.out' });
		gsap.to(this._line2, duration, { delay: 0.2, y: 0, opacity: 1, ease: 'power2.out' });
		gsap.to(this._line3, duration, { delay: 0.0, y: 0, opacity: 1, ease: 'power2.out' });
		gsap.to(this._startButton, duration, { delay: 0.0, y: 0, opacity: 1, ease: 'power2.out' });
		gsap.to(this._homeBottomText, duration, { delay: 0.2, y: 0, opacity: 1, ease: 'power2.out' });
		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { delay: 1, duration: 1, z: 50, ease: 'power3.out' });
		if (Globals.DEBUG_AUTO_SKIP_INTRO_SCREEN) {
			console.log('!!');
			this.enterSiteClicked();
		}
	};

	private enterSiteClicked = (event?: MouseEvent) => {
		this._startButton.removeEventListener('mouseover', this.mouseOverEnterButton);
		this._startButton.removeEventListener('mouseout', this.mouseOutEnterButton);
		this._startButton.removeEventListener('click', this.enterSiteClicked);

		var getPosButton = this._line1.getBoundingClientRect();
		var getFingerSpellingPos = Globals.HAND_SCENE.getPositionIn3D(getPosButton.x, getPosButton.y);

		console.log(getFingerSpellingPos);

		//	gsap.to(getHand.position, { duration: 0.5, x: getFingerSpellingPos.x, ease: 'Power1.inOut' });

		var moveOut = Globals.HAND_SCENE.getPositionIn3D(window.innerWidth, window.innerHeight / 2 + 100);

		gsap.to(this.element, { duration: 0.3, delay: 0.0, y: window.innerHeight * 1.3, ease: 'circ.inOut' });

		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { delay: 0.0, duration: 0.3, y: moveOut.y, ease: 'circ.inOut' });
		gsap.to(getHand.rotation, { duration: 0.3, x: 3.14159265 / 2, ease: 'circ.inOut' });

		gsap.to(getHand.position, { delay: 0.3, duration: 0.3, y: 0, ease: 'circ.inOut' });
		gsap.to(getHand.rotation, { delay: 0.3, duration: 0.3, x: 0, ease: 'circ.inOut', onComplete: this.startPreloader });

		this._setupVideo = new SetupVideo();
		Globals.SETUP_VIDEO = this._setupVideo;
	};

	private onCameraAccesGiven = () => {
		console.log('-- Camera Access');
	};

	private updateText = () => {
		if (this._hintCount >= this._hintTexts.length) {
			this._hintCount = 1;
		}

		this._hintElement.innerHTML = this._hintTexts[this._hintCount];

		this._hintCount++;

		gsap.delayedCall(4, this.updateText);
	};

	private startPreloader = () => {
		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { delay: 0.0, duration: 0.3, y: 0, ease: 'circ.out' });
		gsap.to(getHand.rotation, { delay: 0.0, duration: 0.3, x: 0, y: 0, z: 0, ease: 'circ.out' });

		//	Globals.HAND_SCENE.spellPreloaderAnim();

		Globals.HAND_SCENE.spellSentence('loading');

		this._line2.innerHTML = 'Loading Hand Tracking';
		this._line1.style.display = 'none';
		this._line3.style.display = 'none';
		this._startButton.style.display = 'none';
		this._homeBottomText.style.display = 'none';

		gsap.set(this.element, { y: 0 });
		gsap.set(this._line2, { x: window.innerWidth * -1, opacity: 1, ease: 'power2.out' });
		gsap.to(this._line2, { duration: 0.4, x: 0, opacity: 1, ease: 'power2.out' });

		//this.updateText();

		Globals.HAND_POSE_DETECTION = new HandPoseDetection(this._setupVideo.getVideoFeed());
		if (Globals.DEBUG_TURN_OFF_HANDPOSE) {
			Globals.HAND_POSE_DETECTION.updateVideoWithoutDetectionTurnedOn();
			this.handPoseReady();
		} else {
			Globals.HAND_POSE_DETECTION.loadHandPose(this.handPoseReady);
		}
	};

	private handPoseReady = () => {
		console.log('handpose ready');
		Globals.MAIN.startGlobalRAF();
		this.animateOut();
	};

	private animateOut = () => {
		gsap.killTweensOf(this.updateText);

		var moveOut = Globals.HAND_SCENE.getPositionIn3D(window.innerWidth, window.innerHeight * 1.3);

		gsap.to(this.element, { duration: 0.3, delay: 0.0, x: window.innerWidth, ease: 'circ.in', onComplete: this.kill });

		//	var getHand = Globals.HAND_SCENE.getHand();
		//	gsap.to(getHand.position, {delay: 0.0, duration: 0.3, y: moveOut.y, ease: 'circ.in'});
	};

	public requestCamera = () => {};

	private kill = () => {
		Globals.HAND_SCENE.stopSpelling();

		this.element.parentNode.removeChild(this.element);

		Globals.VIEW_MANAGER = new ViewManager(document.body.querySelector('#ViewContainer'));

		Globals.VIEW_MANAGER.registerView({ name: 'GameView', view: GameView });
		Globals.VIEW_MANAGER.registerView({ name: 'LevelSelectView', view: LevelSelectView });
		Globals.VIEW_MANAGER.registerView({ name: 'ChooseHand', view: ChooseHand });
		Globals.VIEW_MANAGER.registerView({ name: 'LevelStartScreen', view: LevelStartScreen });
		Globals.VIEW_MANAGER.registerView({ name: 'IntroView', view: IntroView });
		Globals.VIEW_MANAGER.registerView({ name: 'LoadingView', view: LoadingView });
		Globals.VIEW_MANAGER.registerView({ name: 'LevelCompletedView', view: LevelCompletedView });
		Globals.VIEW_MANAGER.registerView({ name: 'AboutView', view: AboutView });

		//gsap.to(this._setupVideo.getZoomedCanvas(), 0.3, { autoAlpha: Globals.VIDEO_DEFAULT_ALPHA });
		console.log('view manager rerady');
		Globals.VIEW_MANAGER.init();

		if (Globals.SKIP_TO_MAIN_SCREEN === true) {
			//	Globals.VIEW_MANAGER.setPath('home');
		}
	};
}
