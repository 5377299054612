import { ViewManager } from '../managers/ViewManager';
import { DOMCursor } from '../utils/DOMCursor';
import { HandAnalyzer } from '../utils/HandAnalyzer';
import { HandStateManager } from '../utils/HandStateManager';
import { Preloader } from '../preloader/Preloader';
import { SetupVideo } from '../preloader/SetupVideo';
import { ActiveStateController } from '../utils/ActiveStateController';
import { Alphabet } from '../utils/Alphabet';

export class Words {
	public static fourLetterWords: Array = [
		'abet',
		'abut',
		'ache',
		'alit',
		'ante',
		'arch',
		'aver',
		'avow',
		'bach',
		'back',
		'bade',
		'bail',
		'bait',
		'bake',
		'bale',
		'balk',
		'ball',
		'band',
		'bang',
		'bank',
		'barb',
		'bard',
		'bare',
		'barf',
		'bark',
		'base',
		'bash',
		'bask',
		'bate',
		'bawl',
		'bead',
		'beam',
		'bean',
		'bear',
		'beat',
		'beef',
		'been',
		'beep',
		'bell',
		'belt',
		'bend',
		'bent',
		'bias',
		'bide',
		'biff',
		'bike',
		'bilk',
		'bill',
		'bind',
		'bird',
		'birl',
		'birr',
		'bite',
		'bitt',
		'blab',
		'blat',
		'bled',
		'blew',
		'blob',
		'blot',
		'blow',
		'blub',
		'blue',
		'blur',
		'boat',
		'bode',
		'body',
		'boil',
		'bolt',
		'bomb',
		'bond',
		'bone',
		'bong',
		'bonk',
		'book',
		'boom',
		'boot',
		'bore',
		'born',
		'boss',
		'bowl',
		'brag',
		'bray',
		'bred',
		'brew',
		'brim',
		'buck',
		'buff',
		'bulk',
		'bull',
		'bump',
		'bung',
		'bunk',
		'bunt',
		'buoy',
		'burl',
		'burn',
		'burp',
		'burr',
		'bury',
		'bush',
		'busk',
		'buss',
		'bust',
		'busy',
		'butt',
		'buzz',
		'caca',
		'cage',
		'cake',
		'calk',
		'call',
		'calm',
		'came',
		'camp',
		'cane',
		'cant',
		'card',
		'care',
		'cart',
		'case',
		'cash',
		'cast',
		'cave',
		'cede',
		'cere',
		'chap',
		'char',
		'chat',
		'chaw',
		'chid',
		'chin',
		'chip',
		'chop',
		'chug',
		'chum',
		'cite',
		'clad',
		'clam',
		'clap',
		'claw',
		'clew',
		'clip',
		'clog',
		'clop',
		'clot',
		'cloy',
		'club',
		'clue',
		'coal',
		'coat',
		'coax',
		'cock',
		'code',
		'coif',
		'coil',
		'coin',
		'coke',
		'comb',
		'come',
		'comp',
		'cone',
		'conk',
		'cook',
		'cool',
		'coop',
		'cope',
		'copy',
		'cord',
		'core',
		'cork',
		'corn',
		'cosh',
		'cost',
		'cowl',
		'crab',
		'cram',
		'crap',
		'crew',
		'crib',
		'crop',
		'crow',
		'cube',
		'cuff',
		'cull',
		'curb',
		'cure',
		'curl',
		'cuss',
		'dado',
		'damn',
		'damp',
		'dang',
		'dare',
		'darn',
		'dart',
		'dash',
		'date',
		'daub',
		'dawn',
		'daze',
		'deal',
		'deck',
		'deed',
		'deem',
		'defy',
		'deke',
		'demo',
		'dent',
		'deny',
		'dial',
		'dice',
		'died',
		'diet',
		'dike',
		'dine',
		'ding',
		'disc',
		'dish',
		'disk',
		'diss',
		'dive',
		'dock',
		'doff',
		'dole',
		'done',
		'doom',
		'dope',
		'doss',
		'dote',
		'dove',
		'down',
		'doze',
		'drag',
		'draw',
		'drew',
		'drip',
		'drop',
		'drub',
		'drug',
		'drum',
		'duck',
		'duel',
		'dull',
		'dump',
		'dung',
		'dunk',
		'dupe',
		'dusk',
		'dust',
		'earn',
		'ease',
		'echo',
		'edge',
		'edit',
		'emit',
		'envy',
		'espy',
		'etch',
		'even',
		'exit',
		'face',
		'fade',
		'fail',
		'fake',
		'fall',
		'fare',
		'farm',
		'fart',
		'fast',
		'fate',
		'fawn',
		'faze',
		'fear',
		'feed',
		'feel',
		'fell',
		'felt',
		'fend',
		'fess',
		'fete',
		'feud',
		'file',
		'fill',
		'film',
		'find',
		'fine',
		'fink',
		'fire',
		'firm',
		'fish',
		'fist',
		'fizz',
		'flag',
		'flap',
		'flat',
		'flaw',
		'flay',
		'fled',
		'flee',
		'flew',
		'flex',
		'flip',
		'flit',
		'flog',
		'flop',
		'flow',
		'flub',
		'flux',
		'foal',
		'foam',
		'foil',
		'fold',
		'fond',
		'fool',
		'foot',
		'ford',
		'fork',
		'form',
		'foul',
		'fowl',
		'frag',
		'frap',
		'fray',
		'free',
		'fret',
		'frit',
		'fuel',
		'full',
		'fume',
		'fund',
		'funk',
		'furl',
		'fuse',
		'fuss',
		'futz',
		'gain',
		'gait',
		'gall',
		'game',
		'gang',
		'gaol',
		'gape',
		'garb',
		'gash',
		'gasp',
		'gate',
		'gave',
		'gawk',
		'gawp',
		'gaze',
		'gear',
		'geld',
		'gibe',
		'gift',
		'gild',
		'gimp',
		'gird',
		'give',
		'glom',
		'glow',
		'glue',
		'glug',
		'glut',
		'gnaw',
		'goad',
		'golf',
		'gone',
		'gong',
		'goof',
		'gore',
		'gown',
		'grab',
		'gray',
		'grew',
		'grey',
		'grid',
		'grin',
		'grip',
		'grit',
		'grow',
		'grub',
		'gull',
		'gulp',
		'gush',
		'gust',
		'gybe',
		'hack',
		'hail',
		'hale',
		'halt',
		'hand',
		'hang',
		'hare',
		'hark',
		'harm',
		'harp',
		'hash',
		'hasp',
		'hast',
		'hate',
		'hath',
		'haul',
		'have',
		'hawk',
		'haze',
		'head',
		'heal',
		'heap',
		'hear',
		'heat',
		'heed',
		'heel',
		'heft',
		'held',
		'helm',
		'help',
		'herd',
		'hewn',
		'hide',
		'hike',
		'hill',
		'hint',
		'hire',
		'hiss',
		'hive',
		'hoax',
		'hock',
		'hold',
		'hole',
		'home',
		'hone',
		'honk',
		'hood',
		'hoof',
		'hook',
		'hoop',
		'hoot',
		'hope',
		'horn',
		'hose',
		'host',
		'hove',
		'howl',
		'huff',
		'hulk',
		'hull',
		'hump',
		'hung',
		'hunt',
		'hurl',
		'hurt',
		'hush',
		'husk',
		'hymn',
		'hype',
		'idle',
		'inch',
		'iron',
		'itch',
		'jack',
		'jade',
		'jail',
		'jape',
		'jazz',
		'jeer',
		'jell',
		'jerk',
		'jest',
		'jibe',
		'jilt',
		'jinx',
		'jive',
		'join',
		'joke',
		'jolt',
		'josh',
		'juke',
		'jump',
		'junk',
		'kayo',
		'keel',
		'keen',
		'keep',
		'kept',
		'kern',
		'kick',
		'kill',
		'kink',
		'kiss',
		'kite',
		'knap',
		'knew',
		'knit',
		'knot',
		'know',
		'lace',
		'lack',
		'laid',
		'lain',
		'lamb',
		'lame',
		'land',
		'lard',
		'lark',
		'lash',
		'last',
		'laud',
		'lave',
		'laze',
		'lead',
		'leaf',
		'leak',
		'lean',
		'leap',
		'leer',
		'left',
		'lend',
		'lent',
		'levy',
		'lick',
		'lift',
		'like',
		'lilt',
		'lime',
		'limp',
		'line',
		'link',
		'lisp',
		'list',
		'live',
		'load',
		'loaf',
		'loan',
		'lock',
		'loft',
		'loll',
		'long',
		'look',
		'loom',
		'loop',
		'loot',
		'lope',
		'lord',
		'lose',
		'lost',
		'lour',
		'love',
		'lube',
		'luck',
		'luff',
		'luge',
		'lull',
		'lump',
		'lure',
		'lurk',
		'lust',
		'made',
		'mail',
		'maim',
		'make',
		'mark',
		'mask',
		'mate',
		'maul',
		'meet',
		'meld',
		'melt',
		'mend',
		'meow',
		'mess',
		'miff',
		'milk',
		'mime',
		'mind',
		'mine',
		'miss',
		'moan',
		'mock',
		'moor',
		'moot',
		'mope',
		'move',
		'muck',
		'muff',
		'muse',
		'mush',
		'must',
		'mute',
		'nail',
		'name',
		'near',
		'neck',
		'need',
		'nest',
		'nick',
		'nock',
		'nose',
		'note',
		'nuke',
		'numb',
		'obey',
		'ogle',
		'okay',
		'omen',
		'omit',
		'ooze',
		'open',
		'oust',
		'pace',
		'pack',
		'page',
		'pain',
		'pair',
		'palm',
		'pant',
		'pare',
		'park',
		'part',
		'pass',
		'pave',
		'pawn',
		'peak',
		'peal',
		'peck',
		'peek',
		'peel',
		'peep',
		'peer',
		'perk',
		'pick',
		'pile',
		'pine',
		'plan',
		'plat',
		'play',
		'plod',
		'plop',
		'plot',
		'plow',
		'plug',
		'pock',
		'poke',
		'pool',
		'pore',
		'pose',
		'post',
		'pour',
		'pout',
		'pray',
		'prim',
		'prod',
		'prop',
		'puff',
		'pull',
		'pulp',
		'pump',
		'purl',
		'purr',
		'push',
		'putt',
		'quip',
		'quit',
		'quiz',
		'race',
		'rage',
		'raid',
		'rain',
		'rake',
		'rang',
		'rank',
		'rant',
		'rape',
		'rate',
		'raze',
		'read',
		'ream',
		'reap',
		'reef',
		'reek',
		'reel',
		'rein',
		'rely',
		'rend',
		'rent',
		'rest',
		'rice',
		'ride',
		'rile',
		'ring',
		'riot',
		'rise',
		'risk',
		'rive',
		'roam',
		'roar',
		'rock',
		'rode',
		'roil',
		'roll',
		'romp',
		'roof',
		'room',
		'root',
		'rope',
		'rout',
		'rove',
		'ruin',
		'rule',
		'rush',
		'rust',
		'sack',
		'said',
		'sail',
		'sale',
		'salt',
		'sass',
		'sate',
		'save',
		'sawn',
		'scab',
		'scam',
		'scan',
		'scar',
		'scat',
		'scud',
		'scum',
		'seal',
		'seam',
		'seat',
		'seed',
		'seek',
		'seem',
		'seen',
		'seep',
		'sell',
		'send',
		'sent',
		'sewn',
		'shag',
		'sham',
		'shed',
		'ship',
		'shit',
		'shoe',
		'shoo',
		'shop',
		'shot',
		'show',
		'shun',
		'shut',
		'side',
		'sift',
		'sigh',
		'sign',
		'sing',
		'sink',
		'sire',
		'site',
		'size',
		'skew',
		'skid',
		'skim',
		'skin',
		'skip',
		'slab',
		'slag',
		'slam',
		'slap',
		'slat',
		'slay',
		'sled',
		'slew',
		'slid',
		'slim',
		'slip',
		'slit',
		'slog',
		'slop',
		'slot',
		'slow',
		'slue',
		'slug',
		'slum',
		'slur',
		'smut',
		'snag',
		'snap',
		'snip',
		'snow',
		'snub',
		'soak',
		'soap',
		'soar',
		'sock',
		'soil',
		'sold',
		'sole',
		'solo',
		'sort',
		'sour',
		'sown',
		'spam',
		'span',
		'spar',
		'spat',
		'spay',
		'sped',
		'spew',
		'spin',
		'spit',
		'spot',
		'spud',
		'spur',
		'stab',
		'stag',
		'star',
		'stay',
		'stem',
		'step',
		'stet',
		'stew',
		'stir',
		'stop',
		'stow',
		'stub',
		'stud',
		'stun',
		'suck',
		'suit',
		'sulk',
		'sung',
		'sunk',
		'surf',
		'swab',
		'swam',
		'swap',
		'swat',
		'sway',
		'swig',
		'swim',
		'swob',
		'swot',
		'swum',
		'tack',
		'tail',
		'talc',
		'talk',
		'tame',
		'tamp',
		'tank',
		'tape',
		'task',
		'taxi',
		'team',
		'tear',
		'teem',
		'tell',
		'tend',
		'tent',
		'term',
		'test',
		'text',
		'thaw',
		'thin',
		'thud',
		'tick',
		'tide',
		'tile',
		'tilt',
		'time',
		'ting',
		'tint',
		'tire',
		'toil',
		'told',
		'toll',
		'tone',
		'took',
		'tool',
		'toot',
		'tope',
		'tore',
		'torn',
		'toss',
		'tote',
		'tour',
		'tout',
		'tram',
		'trap',
		'tree',
		'trek',
		'trim',
		'trip',
		'trod',
		'trot',
		'true',
		'tube',
		'tuck',
		'tune',
		'turf',
		'turn',
		'tusk',
		'twin',
		'twit',
		'type',
		'undo',
		'urge',
		'vamp',
		'vary',
		'veer',
		'veil',
		'vein',
		'vend',
		'vent',
		'vest',
		'veto',
		'vide',
		'view',
		'visa',
		'vise',
		'void',
		'vote',
		'wade',
		'waft',
		'wage',
		'wail',
		'wait',
		'wake',
		'walk',
		'wall',
		'wane',
		'want',
		'ward',
		'warm',
		'warn',
		'warp',
		'wash',
		'waul',
		'wave',
		'wawl',
		'wean',
		'wear',
		'weed',
		'weep',
		'weld',
		'well',
		'welt',
		'wend',
		'went',
		'wept',
		'were',
		'wert',
		'wham',
		'whap',
		'whet',
		'whip',
		'whir',
		'whiz',
		'whop',
		'will',
		'wilt',
		'wine',
		'wink',
		'wipe',
		'wire',
		'wish',
		'wist',
		'wive',
		'woke',
		'wolf',
		'woof',
		'word',
		'wore',
		'work',
		'worm',
		'worn',
		'wove',
		'wrap',
		'writ',
		'xray',
		'yack',
		'yank',
		'yarn',
		'yaup',
		'yawn',
		'yawp',
		'yean',
		'yell',
		'yelp',
		'yoke',
		'yowl',
		'zero',
		'zest',
		'zing',
		'zone',
		'zonk',
		'zoom'
	];

	// Avoid ST next to each other
	// PQ are downwards
	// H is sideways
	// Z and J requires motion

	//https://wordfinder.yourdictionary.com/unscramble/abeocluy/
	// Easy ones: ABCELOVWUY
	public static lettersLevel1: Array<string> = ['A', 'B', 'C', 'E', 'L', 'O', 'Y', 'W', 'U', 'Y'];
	public static wordsLevel1: Array<string> = ['Able', 'Love', 'Buy', 'Cube', 'Wavy', 'Bowl', 'Claw', 'You', 'Clay', 'Clue'];
	//public static wordsLevel1: Array<string> = ['Clay', 'Elbow', 'Cable', 'Below', 'Above', 'Vowel', 'Club', 'Cube', 'Blue'];

	// 2nd easiest: DFKRSIT  ---- All together: ABCELOVWUYDFKRSIT
	public static lettersLevel2: Array<string> = ['D', 'F', 'K', 'R', 'S', 'I', 'T'];
	//['Risk', 'Dirt', 'Kids', 'Fits', 'Rest', 'Sir', 'Stir', 'Feud', 'Buff', 'Duke'];
	public static wordsLevel2: Array<string> = ['Risk', 'Sir', 'Dirt', 'Kids', 'Verb', 'Draw', 'Dark', 'Feud', 'Four', 'Cake'];

	// Harder ones (sideways): GHMNX   ---- All together: ABCELOVWUYDFKRSITGHMNX
	public static lettersLevel3: Array<string> = ['G', 'H', 'M', 'N', 'X'];
	public static wordsLevel3: Array<string> = ['Foxy', 'Onyx', 'Gown', 'Honk', 'Minx', 'Hack', 'Claw', 'Hawk', 'Hack', 'Numb'];

	// Hardest (downwards): PQ
	// Hardest with movement: PQJZ  ---- All together: ABCELOVWUYDFKRSITGHMNXPQJZ
	public static lettersLevel4: Array<string> = ['P', 'Q', 'J', 'Z'];
	public static wordsLevel4: Array<string> = ['Quiz', 'Mazy', 'Jive', 'Vape', 'Jump', 'Quiz', 'Squiz', 'Equip', 'Czar', 'Pivot'];
	//'ZJ', 'PQJZ', 'PQJZ', 'PQJZ',
	// ABCDEFGHIJKLMNOPQRSTUVWXYZ
	//
	//
	//
	// Training Level 1: Practice the easiest letters - maybe just 6 letters ? (so you can start to do some basic words?)

	// Training Level 2: Harder letters
	// Training Level 3: PQ letters
	// Training Level 4: Z and J letters
	// Compete - see how low a 'word per minute' score you can get
	// Learn custom word
}
