import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';

import { Coords3D } from '@tensorflow-models/handpose/dist/pipeline';
import { HandScene } from '../../3d/HandScene';
import { Words } from '../../data/Words';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class LevelCompletedView extends AbstractView {
	private _animatableElements = [];
	private _headline: HTMLDivElement;
	private _pointsHeadline: HTMLDivElement;
	private _subHeadline: HTMLDivElement;
	private _backButton: HTMLDivElement;

	public element;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);
		this.element = element;

		Globals.HAND_SCENE.animateBackgroundColor(0xffe090);

		var nextLevelButton: HTMLDivElement = element.querySelector('.nextLevel');

		this._headline = element.querySelector('.headline');
		this._pointsHeadline = element.querySelector('.pointsHeadline');
		this._subHeadline = element.querySelector('.subHeader');
		this._pointsHeadline.innerHTML = Globals.FINAL_SCORE + ' points';
		//this._headline.innerHTML = 'Level ' + Globals.LEVEL;

		if (Globals.LEVEL >= 4) {
			this._subHeadline.innerHTML =
				'You have completed all the levels - and have learned all the letters. If you want to - you can try this level again to improve your score.<br><br>Or go back to re-try any of the other levels.';
			nextLevelButton.style.display = 'none';
		}

		gsap.set(this._headline, { y: window.innerHeight * -1 });
		gsap.set(this._pointsHeadline, { y: window.innerHeight * -1 });
		gsap.set(this._subHeadline, { y: window.innerHeight * -1 });

		this._backButton = element.querySelector('.backArrow');
		this._backButton.addEventListener('mouseover', this.mouseOverBack);
		this._backButton.addEventListener('mouseout', this.mouseOutBack);
		this._backButton.addEventListener('click', this.clickBack);

		var tryAgainButton: HTMLDivElement = element.querySelector('.tryAgain');
		tryAgainButton._type = 'tryAgain';
		tryAgainButton.addEventListener('mouseover', this.mouseOverHandSelect);
		tryAgainButton.addEventListener('mouseout', this.mouseOutHandSelect);
		tryAgainButton.addEventListener('click', this.tryAgain);
		gsap.set(tryAgainButton, { x: window.innerWidth * -1 });
		this._animatableElements.push(tryAgainButton);

		nextLevelButton._type = 'nextLevel';
		nextLevelButton.addEventListener('mouseover', this.mouseOverHandSelect);
		nextLevelButton.addEventListener('mouseout', this.mouseOutHandSelect);
		nextLevelButton.addEventListener('click', this.nextLevel);
		gsap.set(nextLevelButton, { x: window.innerWidth * -1 });
		this._animatableElements.push(nextLevelButton);

		console.log('here');
	}

	private mouseOverBack = event => {
		var getTarget = event.currentTarget;
		//.children[0].children[0].children[0]
		gsap.to(getTarget, { duration: 0.2, scale: 1.2, ease: 'power1.inOut' });
	};

	private mouseOutBack = event => {
		var getTarget = event.currentTarget;
		gsap.to(getTarget, { duration: 0.2, scale: 1, ease: 'power1.inOut' });
	};

	private clickBack = () => {
		Globals.VIEW_MANAGER.setPath('chooseLevel');
	};

	private mouseOverHandSelect = event => {
		var getHand = Globals.HAND_SCENE.getHand();

		var target = event.currentTarget;

		//	Globals.HAND_SCENE.setNewHand(15);

		var getTargetPos = target.getBoundingClientRect();

		var moveTo = Globals.HAND_SCENE.getPositionIn3D(getTargetPos.x, getTargetPos.y + 100);

		//	gsap.to(getHand.position, { duration: 0.2, y: moveTo.y, ease: 'power1.inOut' });
		//	gsap.to(getHand.rotation, {duration: 0.2, x: 3.14159265 / 1.6, ease: 'power1.inOut'});

		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');
		gsap.to(getInnerBG, { duration: 0.2, x: -4, ease: 'power1.in' });
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: '#683AFF' });
	};

	private mouseOutHandSelect = event => {
		var target = event.currentTarget;
		var getInnerBG = target.querySelector('.innerBG');
		var getInnerText = target.querySelector('.innerText');

		var getHand = Globals.HAND_SCENE.getHand();

		//	gsap.to(getHand.position, {duration: 0.2, y: 0, ease: 'power1.easeOut'});

		//gsap.to(getHand.rotation, {duration: 0.2, x: 0, ease: 'power1.easeOut'});

		//	Globals.HAND_SCENE.setNewHand(1);

		//gsap.to(target, { duration: 0.1, backgroundColor: '#ffe090', ease: 'power1.in' });
		var newColor = '#fff6DF';
		if (target._type === 'nextLevel') {
			newColor = '#683aff';
		}
		gsap.to(getInnerText, { duration: 0.2, skewX: '0deg', x: 0, force3D: true, ease: 'power1.out', color: newColor });

		gsap.to(getInnerBG, { duration: 0.2, x: -360, ease: 'power1.out' });
	};

	private nextLevel = event => {
		Globals.LEVEL = Globals.LEVEL + 1;
		Globals.VIEW_MANAGER.setPath('startLevel');
	};

	private tryAgain = event => {
		Globals.VIEW_MANAGER.setPath('game');
	};

	public onRequestAnimationFrame = () => {};

	public in = () => {
		//	Globals.HAND_SCENE.stopSpelling();
		Globals.HAND_SCENE.animateBackgroundColor(0x683aff);

		Globals.MAIN.showTopBar('#ffe090');
		Globals.HAND_SCENE.changeHandColor(0x3109cd);

		gsap.to(this._headline, { duration: 0.4, delay: 0.5, y: 0, ease: 'power2.out' });
		gsap.to(this._pointsHeadline, { duration: 0.4, delay: 0.4, y: 0, ease: 'power2.out' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.3, y: 0, ease: 'power2.out' });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: 0, ease: 'power2.out' });

		gsap.to(this._animatableElements.reverse(), { delay: 0.5, duration: 0.4, x: 0, stagger: 0.1, ease: 'power2.out', onComplete: this.animatedIn });

		Globals.VIEW_MANAGER.addView(this.element);
		Globals.ACTIVE_VIEW = this;
	};

	private animatedIn = () => {
		Globals.HAND_SCENE.playHandAnimation(38);
		this.inComplete();
	};

	public out = () => {
		var tryAgainButton: HTMLDivElement = this.element.querySelector('.tryAgain');
		tryAgainButton.removeEventListener('mouseover', this.mouseOverHandSelect);
		tryAgainButton.removeEventListener('mouseout', this.mouseOutHandSelect);
		tryAgainButton.removeEventListener('click', this.tryAgain);
		var nextLevelButton: HTMLDivElement = this.element.querySelector('.nextLevel');
		nextLevelButton.removeEventListener('mouseover', this.mouseOverHandSelect);
		nextLevelButton.removeEventListener('mouseout', this.mouseOutHandSelect);
		nextLevelButton.removeEventListener('click', this.nextLevel);

		this._backButton.removeEventListener('mouseover', this.mouseOverBack);
		this._backButton.removeEventListener('mouseout', this.mouseOutBack);
		this._backButton.removeEventListener('click', this.clickBack);

		gsap.to(this._headline, { duration: 0.4, delay: 0.0, y: window.innerHeight * -1, ease: 'power2.in' });
		gsap.to(this._pointsHeadline, { duration: 0.4, delay: 0.1, y: window.innerHeight * -1, ease: 'power2.in' });
		gsap.to(this._subHeadline, { duration: 0.4, delay: 0.2, y: window.innerHeight * -1, ease: 'power2.in' });
		gsap.to(this._backButton, { duration: 0.4, delay: 0.0, x: -100, ease: 'power2.out' });

		var getHand = Globals.HAND_SCENE.getHand();
		gsap.to(getHand.position, { duration: 0.4, y: 0, x: 0, ease: 'power1.inOut' });
		gsap.to(this._animatableElements, { duration: 0.4, x: window.innerWidth, stagger: 0.1, ease: 'power2.in' });

		gsap.delayedCall(0.6, this.outComplete);
	};

	kill() {
		super.kill();
	}
}
